import React, { useCallback, useEffect, useState } from "react";
import ModalContainerModule from "../modules/modalContainer";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

export default function PendingTaxPaymentModal({
  modalOpen = false,
  handleCloseModal = () => {},
  handlePayment = () => {},
  params = {
    row: {
      voucherNumber: "12,236.68",
      amount: "1211.4 KES",
      taxType: "withholding",
    },
  },
}) {
  const [paymentMethods, setPaymentMethods] = useState("");
  const [taxType, setTaxTypes] = useState("");

  const [paymentMethod, setPaymentMethod] = useState("");
  const [PRNNumber, setPRNNumber] = useState();
  const [paymentTotal, setPaymentTotal] = useState();

  function handlePaymentMethodOnChange(ev) {
    setPaymentMethod(ev.target.value);
  }

  function handlePRNNumberOnChange(ev) {
    setPRNNumber(ev.target.value);
  }

  function handlePaymentTotalOnChange(ev) {
    setPaymentTotal(ev.target.value);
  }

  function handleProcessPayment() {
    const newPayment = {
      paymentMethod,
      PRNNumber,
      paymentTotal,
    };

    handlePayment(newPayment);
  }

  const fetchPaymentMethods = useCallback(async function () {
    setPaymentMethods([
      {
        label: "Payment Method 1",
        value: "payment_method_1",
      },
      {
        label: "Payment Method 2",
        value: "payment_method_2",
      },
      {
        label: "Payment Method 3",
        value: "payment_method_3",
      },
    ]);

    setTaxTypes([
      {
        label: "Withholding Tax",
        value: "withholding",
      },
      {
        label: "ValueAdded Tax (VAT)",
        value: "vat",
      },
    ]);
  }, []);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  return (
    <ModalContainerModule
      size={"specialReverseJournalEntry"}
      accept={handleProcessPayment}
      cancel={handleCloseModal}
      openModal={modalOpen}
      modalTitle={`Add Tax Voucher Payment`}
      acceptButtonText={"Add Tax Payment"}
    >
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <ResponsiveRow
          sx={{
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",

            width: "100%",
            height: "auto",

            marginBottom: "10px",
            padding: "0px 10px",
          }}
        >
          <Typography variant="h5" color="black" fontWeight="bold">
            Total Voucher Amount
          </Typography>
          <Typography variant="h5" color="primary.dark" fontWeight="bold">
            {params.row.amount}
          </Typography>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexWrap: "nowrap",
            gap: "10px",
          }}
        >
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Enter Payment Method</InputLabel>
            <Select
              label="Enter Payment Method"
              value={paymentMethod.value}
              sx={{ width: "100%" }}
              defaultValue=""
              onChange={handlePaymentMethodOnChange}
              MenuProps={{
                style: {
                  maxHeight: "300px",
                },
              }}
            >
              {paymentMethods && paymentMethods.length > 0 ? (
                paymentMethods.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>{" "}
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Enter PRN Number</InputLabel>
            <OutlinedInput
              label="Enter PRN Number"
              sx={{ width: "100%" }}
              type="number"
              value={PRNNumber}
              onChange={handlePRNNumberOnChange}
            />
          </FormControl>{" "}
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Select Tax Type</InputLabel>
            <Select
              label="Select Tax Type"
              value={taxType.value}
              sx={{ width: "100%" }}
              defaultValue=""
              onChange={handlePaymentMethodOnChange}
              MenuProps={{
                style: {
                  maxHeight: "300px",
                },
              }}
            >
              {taxType && taxType.length > 0 ? (
                taxType.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexWrap: "nowrap",
            gap: "10px",
          }}
        >
          <FormControl
            sx={{ width: { xs: "100%", lg: "100%" }, marginBottom: "10px" }}
          >
            <InputLabel>Enter Amount to Pay</InputLabel>
            <OutlinedInput
              label="Enter Amount to Pay"
              placeholder={params.row.amount}
              sx={{ width: "100%" }}
              type="number"
              value={paymentTotal}
              onChange={handlePaymentTotalOnChange}
            />
          </FormControl>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
