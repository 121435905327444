import { Add } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/system/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { useEffect, useState } from "react";
import {
  adjustForTimezone,
  isEmptyString,
  isNullUndefined,
  tryParseInt,
} from "../../../../util/util";
import dayjs from "dayjs";

/**
 *
 * REQUIRED PROPS
 *
 *
 * setTenantModal
 * setIsTenantSelect
 *
 * billingPeriods
 * chargeItemsTotal
 * leaseChargesTableRows
 * facilityOptions
 * leaseTermOptions
 * leaseTypeChanged
 * onRadioChange
 * populateUnitIds
 * rentDays
 * saveLease
 * selectedLease
 * task
 * updateLease
 *
 */
export default function AddLeaseModal(props) {
  const [leaseTerm, setLeaseTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [leaseTypeCd, setLeaseTypeCd] = useState("");
  const [rentPeriodCode, setRentPeriodCode] = useState("");
  const [rentDay, setRentDay] = useState("");
  const [rentDate, setRentDate] = useState("");
  const [incrementRent, setIncrementRent] = useState("");
  const [incrementPeriod, setIncrementPeriod] = useState("");
  const [rentIncrementPercentage, setRentIncrementPercentage] = useState("");
  const [rentIncrementAmount, setRentIncrementAmount] = useState("");

  useEffect(() => {
    if (
      !isNullUndefined(props.selectedLease) &&
      (props.task === "ActiveLeaseEdit" || props.task === "Edit")
    ) {
      if (
        !isNullUndefined(props.selectedLease.start) &&
        !isEmptyString(props.selectedLease.start)
      ) {
        setStartDate(new Date(props.selectedLease.start));
      }
      if (
        !isNullUndefined(props.selectedLease.end) &&
        !isEmptyString(props.selectedLease.end)
      ) {
        setExpirationDate(new Date(props.selectedLease.end));
      }
      if (
        !isNullUndefined(props.selectedLease.rentDate) &&
        !isEmptyString(props.selectedLease.rentDate)
      ) {
        setRentDate(new Date(props.selectedLease.rentDate));
      }

      setLeaseTerm(props.selectedLease.leaseTerms);
      setLeaseTypeCd(props.selectedLease.leaseTypeCd);
      setRentPeriodCode(props.selectedLease.billingPeriod);
      setRentDay(props.selectedLease.rentDay);

      if (
        !isNullUndefined(props.selectedLease.incrementPeriod) &&
        !isEmptyString(props.selectedLease.incrementPeriod) &&
        tryParseInt(props.selectedLease.incrementPeriod, 0) > 0
      ) {
        setIncrementPeriod(props.selectedLease.incrementPeriod);
        setIncrementRent(true);
        setRentIncrementAmount(props.selectedLease.rentIncrementAmount);
        setRentIncrementPercentage(props.selectedLease.rentIncrementPercentage);
      }
    }
  }, [props.selectedLease]);

  function leaseTermChanged(event) {
    let value = event.target.value;
    let obj = props.selectedLease;
    obj.leaseTerms = value;
    if (obj.leaseTerms === "WILL") {
      obj.expirationDate = null;
    }
    props.setSelectedLease(obj);
    setLeaseTerm(value);
  }

  function handleStartDateChange(value) {
    let date = adjustForTimezone(new Date(value));
    let obj = props.selectedLease;
    obj.effectiveDate = date;
    setStartDate(date);
    props.setSelectedLease(obj);

    //Ensure that all lease details have the same date as Lease Start date
    let newArr = [...props.chargeItems];
    if(newArr.length > 0){
      newArr.forEach((charge, i) => {
        newArr[i].effectiveDate = date
      })
    }
    props.setChargeItems(newArr)
  }

  function handleExpirationDateChange(value) {
    let date = adjustForTimezone(new Date(value));
    let obj = props.selectedLease;
    obj.expirationDate = date;
    setExpirationDate(date);
    props.setSelectedLease(obj);
  }

  function leaseTypeChanged(event) {
    let value = event.target.value;
    let obj = props.selectedLease;
    obj.leaseTypeCd = value;
    props.setSelectedLease(obj);
    setLeaseTypeCd(value);
  }

  function handleRentPeriodChange(event) {
    let value = event.target.value;
    let obj = props.selectedLease;
    obj.billingPeriod = value;
    props.setSelectedLease(obj);
    setRentPeriodCode(value);
  }

  function handleRentDayChange(event) {
    let value = event.target.value;
    let obj = props.selectedLease;
    obj.rentDay = value;
    setRentDay(value);
    props.setSelectedLease(obj);
  }

  function handleNextRentDateChange(value) {
    let date = adjustForTimezone(new Date(value));
    let obj = props.selectedLease;
    obj.rentDate = date;
    props.setSelectedLease(obj);
    setRentDate(date);
  }

  function handleIncrementPeriodChange(event) {
    let text = event.target.value;
    let tmp = props.selectedLease;
    let value = tryParseInt(text, -1);
    if (value < 0) {
      tmp.incrementPeriod = 0;
      setIncrementPeriod(0);
    } else {
      tmp.incrementPeriod = value;
      setIncrementPeriod(value);
    }
    props.setSelectedLease(tmp);
  }

  function handleIncrementRentSwitchToggle(event) {
    setIncrementRent(event.target.checked);
  }

  function handleIncrementChange(event) {
    let tmp = props.selectedLease;
    if (props.incrementType === "Amount") {
      tmp.rentIncrementAmount = event.target.value;
      tmp.rentIncrementPercentage = 0;
      setRentIncrementAmount(event.target.value);
      setRentIncrementPercentage(0);
    } else {
      tmp.rentIncrementAmount = 0;
      tmp.rentIncrementPercentage = event.target.value;
      setRentIncrementAmount(0);
      setRentIncrementPercentage(event.target.value);
    }
    props.setSelectedLease(tmp);
  }

  function handleLeaseChargeItemAdd() {
    let charge = {
      unit: {
        id: "",
        name: "",
      },
      amount: "",
      chargeCode: { code: "", name: "" },
      taxable: false,
      effectiveDate: (isEmptyString(startDate) || isNullUndefined(startDate)) ? new Date() : startDate,
      note: "",
    };
    props.setChargeItems((chargeItems) => [...chargeItems, charge]);
  }

  function onRadioChange(radio) {
    const value = radio.target.value;
    if (radio) {
      if (value === "Percentage") {
        props.setIncrementType(value);
      }
      if (value === "Amount") {
        props.setIncrementType(value);
      }
    }
  }

  return (
    <ModalContainerModule
      size="md"
      accept={
        props.task === "Create" || props.task === "Edit"
          ? props.saveLease
          : props.task === "ActiveLeaseEdit"
          ? props.updateLease
          : () => {}
      }
      cancel={props.handleAddLeaseClose}
      openModal={
        props.task === "Create" ||
        props.task === "Edit" ||
        props.task === "ActiveLeaseEdit"
      }
      // closeModal
      modalTitle={`Lease ${
        props.selectedLease && props.selectedLease.billingCode
      }`}
      acceptButtonText={
        props.task === "Create"
          ? "Create"
          : props.task === "Edit"
          ? "Edit"
          : props.task === "ActiveLeaseEdit"
          ? "Edit Active Lease"
          : ""
      }
    >
      <ResponsiveRow sx={{ paddingTop: 0 }}>
        <Paper
          variant=""
          sx={{
            width: "100%",
            height: "auto",
            margin: "auto",
            padding: "10px",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{ marginBottom: "5px" }}
            id="modal-modal-title"
            variant="h5"
            component="h4"
          >
            Lease Details
          </Typography>
          <ResponsiveRow
            container
            sx={{
              justifyContent: { xs: "center", lg: "space-between" },
            }}
          >
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Lease Terms</InputLabel>
              <Select
                label="Lease Terms"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"leaseTerms"}
                value={leaseTerm}
                onChange={leaseTermChanged}
              >
                {props.leaseTermOptions && props.leaseTermOptions.length > 0 ? (
                  props.leaseTermOptions.map(function (leaseTermItem, i) {
                    return (
                      <MenuItem key={i} value={leaseTermItem.value}>
                        {leaseTermItem.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Start Date"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"startDate"}
                  value={dayjs(startDate)}
                  isrequired
                  disabled={props.task === "ActiveLeaseEdit"}
                  onChange={handleStartDateChange}

                />
              </LocalizationProvider>
            </FormControl>

            {props.selectedLease &&
              props.selectedLease.leaseTerms === "FXD" && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    marginBottom: "10px",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      label="End Date"
                      sx={{ width: { xs: "100%", lg: "90%" } }}
                      id={"expirationDate"}
                      value={dayjs(expirationDate)}
                      isrequired
                      disabled={false}
                      onChange={handleExpirationDateChange}

                    />
                  </LocalizationProvider>
                </FormControl>
              )}

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Lease Type</InputLabel>
              <Select
                label="Lease Type"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"leaseType"}
                value={leaseTypeCd}
                disabled={props.task === "ActiveLeaseEdit"}
                onChange={leaseTypeChanged}
              >
                {props.leaseTypeOptions && props.leaseTypeOptions.length > 0 ? (
                  props.leaseTypeOptions.map(function (leaseType, i) {
                    return (
                      <MenuItem key={i} value={leaseType.value}>
                        {leaseType.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>

            <ResponsiveRow
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 0,
              }}
            >
              <FormControl
                item
                sx={{
                  width: "80%",
                }}
              >
                {props.selectedLease && props.selectedLease.tenant &&
                  props.selectedLease.tenant.name === null && (
                    <InputLabel>Tenant</InputLabel>
                  )}
                <OutlinedInput
                  label="Tenant"
                  sx={{ width: { xs: "100%", lg: "100%" } }}
                  id={"tenant"}
                  value={
                    props.selectedLease &&
                    props.selectedLease.tenant &&
                    props.selectedLease.tenant.name
                  }
                  disabled
                  inputProps={
                    <TextField sx={{ width: { xs: "100%", lg: "90%" } }} />
                  }
                />
              </FormControl>
              {props.task !== "ActiveLeaseEdit" && (
                <IconButton
                  color="primary"
                  aria-label="add Tenant"
                  title={"Add Tenant"}
                  sx={{
                    margin: "auto 10px",
                    height: "50px",
                    width: "50px",
                    backgroundColor: "#037171",
                  }}
                  onClick={() => {
                    props.setTenantModal(true);
                    props.setIsTenantSelect(true);
                  }}
                >
                  <Add sx={{ color: "#fff" }} />
                </IconButton>
              )}
            </ResponsiveRow>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Rent Frequency</InputLabel>
              <Select
                label="Rent Frequency"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"billingPeriod"}
                value={rentPeriodCode}
                onChange={handleRentPeriodChange}
              >
                {props.billingPeriods && props.billingPeriods.length > 0 ? (
                  props.billingPeriods.map(function (billingPeriod, i) {
                    return (
                      <MenuItem key={i} value={billingPeriod.value}>
                        {billingPeriod.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>

            {props.selectedLease &&
              props.selectedLease.billingPeriod === "MNTH" && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Rent Due Day</InputLabel>
                  <Select
                    label="Rent Due Day"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    id={"billingDay"}
                    value={rentDay}
                    onChange={handleRentDayChange}
                    defaultValue={[""]}
                  >
                    {props.rentDays && props.rentDays.length > 0 ? (
                      props.rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={i} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            {props.selectedLease &&
              (rentPeriodCode === "ANU" || rentPeriodCode === "QT") && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    marginBottom: "10px",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      label="Next Rent Due Date"
                      sx={{ width: { xs: "100%", lg: "90%" } }}
                      id={"nextRentDate"}
                      value={dayjs(rentDate)}
                      onChange={handleNextRentDateChange}

                    />
                  </LocalizationProvider>
                </FormControl>
              )}

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Facility</InputLabel>
              <Select
                label="Facility"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"facility"}
                value={props.selectedLease && props.selectedLease.facilityId}
                disabled
                onChange={(event) => console.log()}
              >
                {props.facilityOptions && props.facilityOptions.length > 0 ? (
                  props.facilityOptions.map(function (facility, i) {
                    return (
                      <MenuItem key={i} value={facility.value}>
                        {facility.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
            {props.task !== "ActiveLeaseEdit" && (
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Unit(s)</InputLabel>
                {props.populateUnitIds()}
              </FormControl>
            )}
            {props.task === "ActiveLeaseEdit" && (
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Unit(s)</InputLabel>
                <OutlinedInput
                  label="Unit(s)"
                  sx={{ width: { xs: "100%", lg: "33.3%" } }}
                  value={props.selectedLease && props.selectedLease.unitNames}
                  disabled={true}
                  inputProps={
                    <TextField sx={{ width: { xs: "100%", lg: "90%" } }} />
                  }
                />
              </FormControl>
            )}
            <ResponsiveRow
              sx={{
                width: "100%",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Standard Rent</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={incrementRent}
                        onChange={handleIncrementRentSwitchToggle}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                  <Typography>Incremental Rent</Typography>
                </Stack>
              </FormControl>
            </ResponsiveRow>
            <ResponsiveRow
              sx={{
                width: "100%",
              }}
            >
              {incrementRent && (
                <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
                  <InputLabel>Increment Period (years)</InputLabel>
                  <OutlinedInput
                    label="Increment Period (years)"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    id={"incrementPeriod"}
                    value={incrementPeriod}
                    disabled={false}
                    onChange={handleIncrementPeriodChange}
                    inputProps={
                      <TextField sx={{ width: { xs: "100%", lg: "90%" } }} />
                    }
                  />
                </FormControl>
              )}
              {incrementRent && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginBottom: "10px",
                  }}
                >
                  <FormLabel>Increment Type</FormLabel>

                  <RadioGroup
                    row
                    sx={{ width: "100%" }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="use-radio-group"
                  >
                    <FormControlLabel
                      name="radio"
                      control={
                        <Radio
                          checked={props.incrementType === "Percentage"}
                          value="Percentage"
                          onClick={onRadioChange}
                        />
                      }
                      label="Percentage"
                    />
                    <FormControlLabel
                      name="radio"
                      control={
                        <Radio
                          checked={props.incrementType === "Amount"}
                          value="Amount"
                          onClick={onRadioChange}
                        />
                      }
                      label="Amount"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {incrementRent && props.incrementType === "Percentage" && (
                <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
                  <InputLabel>Rent Increment (%)</InputLabel>
                  <TextField
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    id={"incrementPercentage"}
                    value={rentIncrementPercentage}
                    disabled={false}
                    onChange={handleIncrementChange}
                  />
                </Grid>
              )}
              {incrementRent && props.incrementType === "Amount" && (
                <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
                  <InputLabel>Rent Increment Amount</InputLabel>
                  <TextField
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    id={"incrementAmount"}
                    value={rentIncrementAmount}
                    disabled={false}
                    onChange={handleIncrementChange}
                  />
                </Grid>
              )}
            </ResponsiveRow>
          </ResponsiveRow>
        </Paper>
        <br />
        <Paper
          variant=""
          sx={{
            width: "100%",
            height: "auto",
            margin: "auto",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ marginBottom: "5px" }}
            id="modal-modal-title"
            variant="h5"
            component="h4"
          >
            Lease Charges
          </Typography>
          <TableContainer>
            <Table
              sx={{ minWidth: 650, marginBottom: "10px" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                  <TableCell sx={{ width: "15%" }}>Charge Type</TableCell>
                  <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                  <TableCell sx={{ width: "20%" }}>Start Date</TableCell>
                  <TableCell sx={{ width: "10%" }}>Taxed?|Note</TableCell>
                  <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{props.leaseChargesTableRows}</TableBody>
              <ResponsiveRow container sx={{ width: "100%" }}>
                Total: {props.chargeItemsTotal}
              </ResponsiveRow>
            </Table>
          </TableContainer>

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <ModalAcceptButton
              //   type="submit"
              onClick={handleLeaseChargeItemAdd}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Add Row
            </ModalAcceptButton>
          </Grid>
        </Paper>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
