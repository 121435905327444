import {
  Alert,
  FormControl,
  FormLabel,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FileUpload } from "../../../inputs/fileUpload";


export default function AddMeterReadingsModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * user
   *
   * handleInvoiceLastReadingToggle
   * handleReadingDateChange
   *
   * setAddReading
   * setMeterReadingAmount
   *
   * addMeterReading
   * addReading
   * invoiceLastReading
   * meterReadingAmount
   * readingDate
   * selectedMeter
   *
   */

  return (
    <ModalContainerModule
      size="specialAddMeterReadingModal"
      accept={props.addMeterReading}
      cancel={() => props.setAddReading(false)}
      openModal={props.addReading}
      modalTitle={`  Meter Readings for ${
        props.selectedMeter && props.selectedMeter.name
      }`}
      acceptButtonText="Add Reading"
    >
      <Typography variant="h5" sx={{ marginY: "10px" }}>
        Last Meter Reading Amount:{" "}
        {props.selectedMeter && props.selectedMeter.lastMeterReading}
      </Typography>
      <ResponsiveRow sx={{ width: "100%", height: "auto" }} container>
        {(props.user.proxyRole === "BLDIR" ||
          props.user.proxyRole === "SYSAD") && (
          <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
            <Alert
              severity="info"
              sx={{ margin: "0 0px", marginBottom: "10px", minHeight: "80px" }}
            >
              Invoicing the new reading will mean an invoice is sent out to any
              active leases for the meter's unit. No standing charge will be
              sent with this invoice as it will always be charged in the invoice
              sent for each rent period (monthly, quarterly etc).
            </Alert>
            <FormControl sx={{ width: "100%", marginTop: "0px" }}>
              <FormLabel>Invoice Reading</FormLabel>
              <Switch
                checked={props.invoiceLastReading}
                onChange={(event) =>
                  props.handleInvoiceLastReadingToggle(event)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </FormControl>
          </ResponsiveRow>
        )}
        <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"readingDate"}
              value={dayjs(props.readingDate)}
              required={false}
              onChange={(newDate) => props.handleReadingDateChange(newDate)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
          <InputLabel>Reading</InputLabel>
          <OutlinedInput
            label="Reading"
            id="meterReadingAmount"
            value={props.meterReadingAmount}
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              props.setMeterReadingAmount(value);
            }}
            inputProps={<TextField sx={{ width: { xs: "100%", md: "90%" } }} />}
          />
        </FormControl>
      </ResponsiveRow>
      <br />
      <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
        <Alert
            severity="info"
            sx={{ marginTop: "1%", marginBottom: "1%" }}
        >
            Upload a meter readings image.
        </Alert>
        <FileUpload
            id={"bulkFileInput"}
            bulkFileprogress={props.fileProgress}
            onChange={(e) => props.setFile(e.target.files[0])}
            accept={".png, .jpg, .jpeg"}
        />
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
