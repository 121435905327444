// export const API_URL = "http://127.0.0.1:9000/api"; //Local
export const API_URL = 'https://app.stage.smartkodi.co.ke/api'; //Test
// export const API_URL = 'https://app.smartkodi.co.ke/api'; //Prod
const LIST_URL = API_URL + "/list";
const DASHBOARD_URL = API_URL + "/dashboard";
const PRINT_URL = API_URL + "/print";
const LISTINGS_URL = API_URL + "/listings";
const LISTINGS_LIST_URL = LISTINGS_URL + "/list";
const REPAIR_URL = API_URL + "/repair";
const TEST_URL = API_URL + "/test";

//AUTH & PROXY URLS
export const LOGIN_URL = API_URL + "/login";
export const GET_ACCESS_TOKEN_URL = API_URL + "/getAccessToken";
export const LOGOUT_URL = API_URL + "/logout";
export const PROXY_URL = API_URL + "/proxy";
export const FORGOT_PASSWORD_URL = API_URL + "/forgotPassword";
export const CHANGE_PASSWORD_URL = API_URL + "/changePassword?token=";
export const CONFIRM_FACEBOOK_DELETE_URL =
  API_URL + "/confirmFacebookDelete?confirmation_code=";
export const RESET_PASSWORD_URL = API_URL + "/resetPassword";
export const RESET_PROFILE_PASSWORD_URL = API_URL + "/resetProfilePassword";

// SIGN UP & SELF SIGN UP URLS
export const FETCH_SIGNUP_URL = API_URL + "/fetchSignUp";
export const SIGNUP_URL = API_URL + "/signUp";
export const GET_STARTED_URL = API_URL + "/getStarted";
export const UPLOAD_TEMPLATE_URL = API_URL + "/uploadTemplate";
export const GET_SIGNUP_TEMPLATE_URL =
  API_URL + "/getSignUpTemplate?serviceCode=";
export const GET_SERVICE_RATE_URL = API_URL + "/getServiceRate?serviceCode=";
export const SEND_VERIFICATION_CODE_URL = API_URL + "/sendVerificationCode";
export const VERIFY_CODE_URL = API_URL + "/verifyCode";
export const LIST_SIGNUPS_URL = API_URL + "/listSignUps";
export const TOTAL_SIGNUP_URL = API_URL + "/totalSignUps";
export const EXPORT_SIGNUPS_URL = API_URL + "/exportSignUps";

//DASHBOARD URLS
export const PENDING_APPROVALS_URL = DASHBOARD_URL + "/pendingApprovals";
export const VACATE_NOTICES_URL = DASHBOARD_URL + "/vacantNotices";
export const INCOME_EXPENSE_URL = DASHBOARD_URL + "/ownerIncomeVsExpense?year=";
export const WO_TILE_URL = DASHBOARD_URL + "/workOrderTile";
export const TENANCY_TILE_URL = DASHBOARD_URL + "/tenancyTile";
export const BALANCE_BY_DAYS_URL = DASHBOARD_URL + "/balancesOverdueByDays";
export const UTILITIES_WORKING_VS_BROKEN_URL =
  DASHBOARD_URL + "/utilitiesWorkingVsBroken";
export const UTILITIES_READ_VS_UNREAD_URL =
  DASHBOARD_URL + "/utilitiesReadVsUnread";
export const LEASE_ENDING_LIST_URL = DASHBOARD_URL + "/leasesEndingList";
export const UNIT_VACANCY_LIST_URL = DASHBOARD_URL + "/unitAndVacancyList";
export const UPCOMING_ASSET_MAINT_URL =
  DASHBOARD_URL + "/upcomingAssetsMaintenance";
export const UPCOMING_BILL_REMINDERS_URL =
  DASHBOARD_URL + "/upcomingBillReminders";
export const DASHBOARD_REMINDERS_URL = DASHBOARD_URL + "/dashboardReminders";
export const PENDING_PAYMENTS_URL = DASHBOARD_URL + "/pendingPayments";
export const BILLS_INVOICES_URL = DASHBOARD_URL + "/fBillsInvoicesTiles";
export const UTILITY_INCOME_VS_EXPENSE_URL =
  DASHBOARD_URL + "/utilitiesIncomeVsExpense?year=";
export const ASSET_INCOME_VS_EXPENSE_URL =
  DASHBOARD_URL + "/assetsIncomeVsExpenses?year=";
export const SYS_ADMIN_MAP_FACILITIES_URL =
  DASHBOARD_URL + "/sysAdminMapFacilities";
export const INCOME_VS_EXPENSE_URL = DASHBOARD_URL + "/incomeVsExpense?year=";
export const EXPENSES_BY_VENDOR_URL = DASHBOARD_URL + "/expensesByVendor?year=";
export const BUDGET_VS_EXPENSE_URL = DASHBOARD_URL + "/budgetVsExpenses?year=";
export const SUBMITTED_APPROVALS_URL = DASHBOARD_URL + "/submittedApprovals";
export const HOMEOWNER_DUES_URL = DASHBOARD_URL + "/homeOwnerDues";
export const OWNER_PENDING_APPROVALS_URL =
  DASHBOARD_URL + "/ownerPendingApprovals";
export const APPROVED_CAPITAL_FUND_URL =
  DASHBOARD_URL + "/approvedCapitalFunds";
export const PATROL_ACTIVITY_GRID_URL = DASHBOARD_URL + "/patrolActivityGrid";
export const ASSIGNED_WORK_ORDERS_URL = DASHBOARD_URL + "/assignedWorkOrders";
export const BULK_METER_SALES_INCOME_URL =
  DASHBOARD_URL + "/bulkMeterSalesIncome?year=";
export const RETAIL_METER_SALES_INCOME_URL =
  DASHBOARD_URL + "/retailMeterSalesIncome?year=";
export const METER_VOLUME_SALES_URL = DASHBOARD_URL + "/meterVolumeSales";
export const DATA_CHARGES_INVOICE_URL =
  DASHBOARD_URL + "/getDataFromChargesAndInvoices";

export const RUN_INV_JOB_URL = API_URL + "/runInvoiceJob";
export const RUN_HOA_INV_JOB_URL = API_URL + "/runHOAInvoiceJob";
export const RUN_UTL_INV_JOB_URL = API_URL + "/runUtilitiesInvoicingJob";
export const RUN_CLT_BILL_URL = API_URL + "/runClientBillingJob";
export const RUN_BILL_RMD_URL = API_URL + "/billReminderForOrgOn";
export const RUN_MT_BILL_URL = API_URL + "/runMeterBillInvoiceJob";
export const RUN_AST_MAINT_JOB_URL = API_URL + "/runAssetMaintenanceJob";
// export const RUN_BLK_WTR_URL = API_URL + "/runBlkWtrInvoiceJob"
export const FIX_JE_URL = API_URL + "/fixJEs ";
export const FIX_PP_URL = API_URL + "/fixPrePayment";
export const SUBS_REPORT_URL = API_URL + "/subscriptionsOverviewReport";
export const UNIT_REPORT_URL = API_URL + "/unitOverviewReport";
export const MPESA_REPORT_URL = API_URL + "/mpesaOverviewReport";
export const EMAIL_REPORT_URL = API_URL + "/";

// SHARED USE URL ENDPOINTS
export const SAVE_CREDIT_NOTE_URL = API_URL + "/saveCredit";
export const PERSON_ORG_SEARCH_URL = API_URL + "/search/personOrgSearch";
export const SAVE_DEBIT_NOTE_URL = API_URL + "/saveCharge";
export const PERSON_SEARCH_URL = API_URL + "/search/personSearch";
export const DOWNLOAD_FILE_FILENAME_URL =
  API_URL + "/getDocumentByFilename?filename=";
export const DOWNLOAD_FILE_DOCID_URL = API_URL + "/getDocumentById?documentId=";
export const UPLOAD_VACATE_NOTICE_DOCUMENT =
  API_URL + "/uploadVacateNoticeDocument?vacateNoticeId=";
export const DELETE_VACATE_NOTICE_DOCUMENT =
  API_URL + "/deleteVacateNoticeDocument";

// REPAIR URL ENDPOINTS
export const REBUILD_LEASE_HISTORY_URL =
  REPAIR_URL + "/rebuildLeaseHistory?billingCode=";
export const REBUILD_OWNERSHIP_HISTORY_URL =
  REPAIR_URL + "/rebuildOwnershipHistory?billingCode=";

// METER_BILLING URL ENDPOINTS
export const LIST_METER_BILLINGS_URL = API_URL + "/listBillings?reference=";
export const TOTAL_METER_BILLINGS_URL =
  API_URL + "/listTotalBillings?reference=";
export const METER_BILLING_PAYMENT_METHOD_URL =
  API_URL + "/list/meterBillingPaymentMethods";
export const OUTSTANDING_METER_BILLING_INVOICES_CHARGES_URL =
  API_URL + "/outstandingMeterBillingInvoicesCharges?meterBillingId=";
export const SAVE_METER_BILLING_PAYMENT_URL =
  API_URL + "/saveMeterBillingPayment";
export const METER_BILLING_PAYMENT_PERIODS_URL =
  API_URL + "/list/meterBillingPeriods";
export const SAVE_METER_BILLING_NOTE_URL = API_URL + "/saveMeterBillingNote";
export const SUSPEND_METER_BILLING_URL =
  API_URL + "/suspendReActivateMeterBilling";
export const TERMINATE_METER_BILLING_URL = API_URL + "/terminateMeterBilling";
export const SAVE_METER_BILLING_URL = API_URL + "/saveMeterBilling";
export const UPDATE_METER_BILLING_URL = API_URL + "/updateMeterBilling";
export const UPLOAD_BILLING_DOCUMENT_URL =
  API_URL + "/uploadBillingDocument?meterBillingId=";
export const DELETE_BILLING_DOCUMENT_URL =
  API_URL + "/deleteBillingDocument?meterBillingId=";
export const LIST_NOTES_URL = API_URL + "/meterNotes?meterBillingId=";
export const METER_BILLING_FINANCIALS_URL =
  API_URL + "/meterBillingFinancials?meterBillingId=";
export const LIST_TERMINATED_METER_BILLINGS_URL =
  API_URL + "/listTerminatedMeterBillings?reference=";
export const ACTIVATE_BILLING_URL = API_URL + "/activateBilling";
export const RESEND_LAST_METER_BILLING_INVOICE_URL =
  API_URL + "/resendLastMeterBillingInvoice";
export const RESEND_LAST_METER_BILLING_RECEIPT_URL =
  API_URL + "/resendMeterBillingReceipt";
export const RESEND_METER_BILLING_STATEMENT_URL =
  API_URL + "/sendMeterBillingStatement";
export const METER_BILLING_ACCOUNT_URL =
  API_URL + "/getMeterBillingTerminationAccounts?meterBillingId=";
export const EXPORT_METER_BILLINGS_URL = API_URL + "/exportMeterBillings";
export const DOWNLOAD_METER_BILLINGS_URL = API_URL + "/downloadMeterBillings";
export const BULK_FACILITY_URL = API_URL + "/processBulkFacility";
export const BULK_FACILITY_HOA_URL = API_URL + "/processBulkFacilityHOA";
export const BULK_FACILITY_METER_BILLINGS_URL =
  API_URL + "/processBulkMeterBilling";
export const BULK_UPLOAD_MAINTENANCE_FACILITIES_URL =
  API_URL + "/processBulkMaintenanceService";
export const DELETE_DRAFT_URL = API_URL + "/deleteDraftLease";

// OWNERSHIPS URL ENDPOINTS
export const LIST_OWNERSHIPS_URL = API_URL + "/listTitles?reference=";
export const TOTAL_OWNERSHIPS_URL = API_URL + "/totalTitles?reference=";
export const TITLE_DETAIL_URL = API_URL + "/titleDetail?titleId=";
export const LIST_TERMINATED_OWNERSHIPS_URL =
  API_URL + "/listTerminatedOwnerships?reference=";
export const TOTAL_TERMINATED_OWNERSHIPS_URL =
  API_URL + "/totalTerminatedOwnerships?reference=";
export const OUTSTANDING_TITLE_INVOICES_CHARGES_URL =
  API_URL + "/outstandingTitleInvoicesCharges?titleId=";
export const SAVE_OWNERSHIP_PAYMENT_URL = API_URL + "/saveTitlePayment";
export const SAVE_OWNERSHIP_NOTE_URL = API_URL + "/saveTitleNote";
export const OWNERSHIP_TERMINATION_ACCOUNT_URL =
  API_URL + "/getTitleTerminationAcc?titleId=";
export const OWNERSHIP_TERMINATION_URL = API_URL + "/terminateTitle";
export const SAVE_OWNERSHIP_URL = API_URL + "/saveTitle";
export const UPDATE_OWNERSHIP_URL = API_URL + "/updateTitle";
export const OWNERSHIP_NOTES_URL = API_URL + "/titleNotes?titleId=";
export const OWNERSHIP_FINANCIALS_URL = API_URL + "/titleFinancials?titleId=";
export const EXPORT_OWNERSHIPS_URL = API_URL + "/exportTitles";
export const DOWNLOAD_OWNERSHIPS_URL = API_URL + "/downloadTitles";
export const GET_OWNERSHIP_CONTACTS_URL =
  API_URL + "/viewTitleContacts?titleId=";
export const REMOVE_OWNERSHIP_CONTACT_URL = API_URL + "/removeTitleContact";
export const ADD_OWNERSHIP_CONTACT_URL = API_URL + "/addTitleContact";
export const BULK_EDIT_OWNERSHIPS_URL = API_URL + "/bulkEditTitle";
export const UPLOAD_TITLE_DOC_URL = API_URL + "/uploadTitleDocument?titleId=";
export const DELETE_TITLE_DOC_URL = API_URL + "/deleteTitleDocument?titleId=";
export const RESEND_LAST_OWNERSHIP_INVOICE_URL =
  API_URL + "/resendLastTitleInvoice";
export const RESEND_LAST_OWNERSHIP_RECEIPT_URL =
  API_URL + "/resendTitleReceipt";
export const RESEND_OWNERSHIP_STATEMENT_URL = API_URL + "/emailTitleStatement";

// LEASES URL ENDPOINTS
export const OUTSTANDING_LEASE_INVOICES_CHARGES_URL =
  API_URL + "/outstandingLeaseInvoicesCharges?leaseId=";
export const GET_MPESA_PAYMENT_BY_CODE_URL =
  API_URL + "/fetchPaymentByCode?confirmationCode=";
export const LEASE_TERMINATION_ACCOUNT_URL =
  API_URL + "/getLeaseTerminationAcc?leaseId=";
export const LEASE_FINANCIALS_URL = API_URL + "/leaseFinancials?leaseId=";
export const LIST_LEASES_URL = API_URL + "/listLeases?reference=";
export const LIST_TERMINATED_LEASES_URL =
  API_URL + "/listTerminatedLeases?reference=";
export const TOTAL_LEASES_URL = API_URL + "/totalLeases?reference=";
export const TOTAL_TERMINATED_LEASES_URL =
  API_URL + "/totalTerminatedLeases?reference=";
export const LEASE_NOTES_URL = API_URL + "/leaseNotes?leaseId=";
export const SAVE_LEASE_NOTE_URL = API_URL + "/saveLeaseNote";
export const ADD_LEASE_CONTACT_URL = API_URL + "/addLeaseContact";
export const GET_LEASE_CONTACTS_URL = API_URL + "/viewLeaseContacts?leaseId=";
export const REMOVE_LEASE_CONTACT_URL = API_URL + "/removeLeaseContact";
export const SAVE_LEASE_PAYMENT_URL = API_URL + "/saveLeasePayment";
export const GET_LEASE_DETAILS_URL = API_URL + "/leaseDetail?leaseId=";
export const DOWNLOAD_LEASES_URL = API_URL + "/downloadLeases";
export const EXPORT_LEASES_URL = API_URL + "/exportLeases";
export const LEASE_TERMINATION_URL = API_URL + "/terminateLease";
export const SUSPEND_REACTIVATE_LEASE_URL = API_URL + "/suspendReActivateLease";
export const LEASE_UNIT_CHARGES_URL = API_URL + "/unitCharges";
export const SAVE_LEASE_URL = API_URL + "/saveLease";
export const DELETE_DRAFT_LEASE_URL = API_URL + "/deleteDraftLease";
export const ACTIVATE_LEASE_URL = API_URL + "/activateLease";
export const UPDATE_LEASE_URL = API_URL + "/updateLease";
export const GET_EDIT_UNIT_DETAILS_URL = API_URL + "/editUnitDetails?leaseId=";
export const ADD_OR_DELETE_UNIT_URL = API_URL + "/addOrDeleteUnit";
export const GET_UNIT_METERS_URL = API_URL + "/getUnitMeters?unitId=";
export const UPLOAD_LEASE_DOCUMENT = API_URL + "/uploadLeaseDocument?leaseId=";
export const DELETE_LEASE_DOCUMENT = API_URL + "/deleteLeaseDocument?leaseId=";
export const RESEND_LAST_INVOICE_URL = API_URL + "/resendLastLeaseInvoice";
export const RESEND_LAST_RECEIPT_URL = API_URL + "/resendLeaseReceipt";
export const RESEND_LAST_STATEMENT_URL = API_URL + "/emailLeaseStatement";
export const INCREMENT_RENT_URL = API_URL + "/incrementRent";
export const SUSPENDED_LEASES_URL = API_URL + "/listSuspendedLeases";

// WORK ORDERS URL ENDPOINTS
export const LIST_WORK_ORDERS_URL = API_URL + "/listWorkOrders?status=";
export const TOTAL_WORK_ORDERS_URL = API_URL + "/totalWorkOrders?status=";
export const WORK_ORDER_NOTES_URL = API_URL + "/getWorkOrderNotes?id=";
export const SAVE_WORK_ORDER_NOTE_URL = API_URL + "/addWorkOrderNote";
export const UPLOAD_WORK_ORDER_DOCUMENT =
  API_URL + "/uploadWorkOrderDocument?id=";
export const FETCH_WORK_ORDER_URL = API_URL + "/fetchWorkOrder?workOrderId=";
export const REMOVE_WORK_ORDER_DETAIL_URL =
  API_URL + "/removeWorkOrderDetail?workOrderDetailId=";
export const APPROVE_WORK_ORDER_DETAIL_URL =
  API_URL + "/approveWorkOrderDetail?workOrderDetailId=";
export const COMPLETE_WORK_ORDER_URL = API_URL + "/completeWorkOrder?id=";
export const REJECT_WORK_ORDER_URL = API_URL + "/rejectWorkOrder";
export const APPROVE_WORK_ORDER_URL = API_URL + "/approveWorkOrder";
export const DELETE_WORK_ORDER_URL = API_URL + "/deleteWorkOrder";
export const CREATE_BILL_PAYMENT_URL = API_URL + "/createBillPayment";
export const FETCH_PERSON_URL = API_URL + "/fetchPerson?personId=";
export const ADD_WORK_ORDER_CHARGE_URL = API_URL + "/addWorkOrderCharge";
export const ADD_WORK_ORDER_VENDOR_URL = API_URL + "/addWorkOrderVendor";
export const SAVE_WORK_ORDER_URL = API_URL + "/saveWorkOrder";
export const DELETE_WORK_ORDER_DOC_URL =
  API_URL + "/deleteWorkOrderDoc?documentId=";
export const BULK_APPROVE_WORK_ORDER_URL = API_URL + "/bulkApproveWorkOrders";
export const BULK_REJECT_WORK_ORDER_URL = API_URL + "/bulkRejectWorkOrders";

// QUOTATIONS URL ENDPOINTS
export const LIST_QUOTATIONS_URL = API_URL + "/listQuotations?statusCd=";
export const LIST_ALL_QUOTATIONS_URL = API_URL + "/listAllQuotations?statusCd=";
export const TOTAL_QUOTATIONS_URL = API_URL + "/totalQuotations?statusCd=";
export const FETCH_QUOTATION_URL = API_URL + "/fetchQuotation?quotationId=";
export const FETCH_QUOTATION_ITEM_URL =
  API_URL + "/fetchQuotationItem?quotationItemId=";
export const UPLOAD_QUOTATION_DOCUMENT =
  API_URL + "/uploadQuotationDocument?id=";
export const DELETE_QUOTATION_DOC_URL =
  API_URL + "/deleteQuotationDoc?documentId=";
export const SAVE_QUOTATION_URL = API_URL + "/saveQuotation";
export const APPROVE_QUOTATION_URL = API_URL + "/approveQuotation";
export const REJECT_QUOTATION_URL = API_URL + "/rejectQuotation";
export const DELETE_QUOTATION_URL = API_URL + "/deleteQuotation";
export const GENERATE_WORK_ORDER_DETAILS_URL =
  API_URL + "/generateWorkOrderDetails";
export const LIST_QUOTATION_ITEMS_TO_GEN_WO_DETAILS_URL =
  API_URL + "/quotationItemsToGenWoDetails?quotationId=";

// BILLS URL ENDPOINTS
export const LIST_BILLS_URL = API_URL + "/listBills";
export const TOTAL_BILLS_URL = API_URL + "/totalBills";
export const DELETE_BILL_URL = API_URL + "/deleteBill";
export const FETCH_BILL_URL = API_URL + "/viewBill?billId=";
export const UPLOAD_BILL_DOCUMENT = API_URL + "/uploadBillDocument?billId=";
export const DELETE_BILL_DOCUMENT = API_URL + "/deleteBillDocument";
export const SAVE_BILL_URL = API_URL + "/saveBill";

// PAYMENT URL ENDPOINTS
export const LIST_PAYMENTS_URL = API_URL + "/listPayments";
export const TOTAL_PAYMENTS_URL = API_URL + "/totalPayments";
export const FETCH_PAYMENT_URL = API_URL + "/viewPayment?paymentId=";
export const UPLOAD_PAYMENT_DOCUMENT =
  API_URL + "/uploadPaymentDocument?paymentId=";
export const DELETE_PAYMENT_DOCUMENT = API_URL + "/deletePaymentDocument";
export const APPROVE_PAYMENT_URL = API_URL + "/approvePayment";
export const REJECT_PAYMENT_URL = API_URL + "/rejectPayment";
export const UNPAID_AND_PARTIAL_BILLS_URL =
  API_URL + "/listUnpaidAndPartialBills?vendorId=";
export const SAVE_PAYMENT_URL = API_URL + "/savePayment";

// BILL REMINDER URL ENDPOINTS
export const LIST_BILL_REMINDERS_URL = API_URL + "/listBillReminders";
export const SAVE_BILL_REMINDER_URL = API_URL + "/saveBillReminder";
export const DELETE_BILL_REMINDER_URL = API_URL + "/deleteBillReminder";

// METERS URL ENDPOINTS
export const LIST_METERS_URL = API_URL + "/listMeters?name=";
export const LIST_ALL_METERS_URL = API_URL + "/listAllMeters?name=";
export const LIST_TOTAL_METERS_URL = API_URL + "/totalMeters?name=";
export const LIST_ARCHIVED_METERS_URL = API_URL + "/listArchivedMeters?name=";
export const ARCHIVE_METER_URL = API_URL + "/archiveMeter";
export const SAVE_METER_URL = API_URL + "/saveMeter";
export const SAVE_READING_URL = API_URL + "/saveReading";
export const UPLOAD_READINGS_URL = API_URL + "/uploadReadings";
export const EXPORT_UTILITIES_URL = API_URL + "/exportUtilities?includeMedia=";
export const EDIT_READING_URL = API_URL + "/editReading";
export const DELETE_READING_URL = API_URL + "/deleteReading";
export const INVOICE_READING_URL = API_URL + "/invoiceReading";
export const ACTIVATE_METER_URL = API_URL + "/activateMeter?meterId=";
export const BULK_EDIT_METER_URL = API_URL + "/bulkEditMeter";
export const METER_FINANCIALS_URL = API_URL + "/meterFinancials?meterId=";
export const METER_READINGS_BULK_UPLOAD_TEMPLATE_URL =
  API_URL + "/getMeterReadingsBulkUploadTemplate";
export const SAVE_READING_BULK_URL = API_URL + "/saveReadingBulk";
export const METER_REVENUE_URL = API_URL + "/meterRevenues?meterId=";
export const METER_READINGS_URL = API_URL + "/meterReadings?meterId=";
export const REPORT_FIXED_METER_URL = API_URL + "/reportFixedMeter";

// COMPLEX URL ENDPOINTS
export const LIST_ORG_COMPLEX_URL = API_URL + "/listOrgComplex";
export const CREATE_COMPLEX_URL = API_URL + "/createComplex";
export const UPDATE_COMPLEX_URL = API_URL + "/updateComplex";

// PROPERTY URL ENDPOINTS
export const FETCH_FACILITY_URL = API_URL + "/fetchFacility?facilityId=";
export const FETCH_FACILITY_NAME_URL =
  API_URL + "/fetchFacilityName?facilityId=";
export const LIST_FACILITYUNITS_URL =
  API_URL + "/fetchFacilityUnits?facilityId=";
export const SAVE_PROPERTY_URL = API_URL + "/saveProperty";
export const SAVE_UNIT_URL = API_URL + "/saveUnit";
export const EDIT_UNITS_URL = API_URL + "/editUnits";
export const DELETE_UNIT_URL = API_URL + "/deleteUnit";
export const EXPORT_PROPERTIES_URL = API_URL + "/exportProperty";
export const GET_PROPERTY_FILE_URL = API_URL + "/getPropertyFile?filename=";

// ASSETS URL ENDPOINTS
export const LIST_ASSETS_URL = API_URL + "/listAssets?name=";
export const SAVE_ASSETS_URL = API_URL + "/saveAsset";
export const DELETE_ASSET_URL = API_URL + "/deleteAsset";

// INSPECTION URL ENDPOINTS
export const LIST_ASSET_INSPECTIONS_URL =
  API_URL + "/listAssetInspections?assetId=";
export const LIST_PROPERTY_INSPECTIONS_URL =
  API_URL + "/listPropertyInspections?facilityId=";
export const LIST_COMPLEX_INSPECTIONS_URL =
  API_URL + "/listComplexInspections?complexId=";
export const LIST_UNIT_INSPECTIONS_URL =
  API_URL + "/listUnitInspections?unitId=";
export const LIST_WORK_ORDER_INSPECTIONS_URL =
  API_URL + "/listWorkOrderInspections?workOrderId=";
export const UPLOAD_INSPECTION_URL = API_URL + "/uploadInspection";
export const GET_INSPECTION_FILE_URL = API_URL + "/getInspectionFile?filename=";
export const DELETE_INSPECTION_DOCUMENT_URL =
  API_URL + "/deleteInspectionDocument";

//MESSAGES URL ENDPOINTS
export const GET_SENT_MESSAGES_URL = API_URL + "/getMySentMessages?pageNo=";
export const GET_RECEIVED_MESSAGES_URL =
  API_URL + "/getReceivedMessages?pageNo=";
export const SEND_MESSAGE_URL = API_URL + "/sendMessage";
export const MARK_AS_READ_URL = API_URL + "/markMessageRead";

//REPORTS URL ENDPOINTS
export const DOWNLOAD_BALANCE_SHEET_URL =
  API_URL + "/reports/downloadBalanceSheet";
export const DOWNLOAD_VAT_REPORT_URL = API_URL + "/reports/downloadVATReport";
export const DOWNLOAD_WITHHOLDING_TAX_REPORT_URL =
  API_URL + "/reports/downloadWithholdingTaxReport";
export const DOWNLOAD_VAT_IN_REPORT_URL =
  API_URL + "/reports/downloadVATInReport";
export const DOWNLOAD_VAT_OUT_REPORT_URL =
  API_URL + "/reports/downloadVATOutReport";
export const DOWNLOAD_RENT_ROLL_REPORT_URL =
  API_URL + "/reports/downloadRentRollReport";
export const DOWNLOAD_WATER_INCOME_VS_EXPENSE_REPORT_URL =
  API_URL + "/reports/downloadUtilitiesWaterIncomeVsExpenseReport";
export const DOWNLOAD_ELECTRICITY_INCOME_VS_EXPENSE_REPORT_URL =
  API_URL + "/reports/downloadUtilitiesElecIncomeVsExpenseReport";
export const DOWNLOAD_PAYMENT_ROLL_REPORT_URL =
  API_URL + "/reports/downloadPaymentRollReport";
export const DOWNLOAD_MOBILE_MONEY_REPORT_URL =
  API_URL + "/reports/downloadMobileMoneyReport";
export const DOWNLOAD_SMARTKODI_MOBILE_MONEY_REPORT_URL =
  API_URL + "/reports/downloadSmartkodiMobileMoneyReport";
export const DOWNLOAD_RECONCILIATION_REPORT_URL =
  API_URL + "/reports/downloadReconciliationReport";
export const DOWNLOAD_BUDGET_VS_ACTUAL_REPORT_URL =
  API_URL + "/reports/downloadBudgetVsActualReport";
export const LIST_APPROVED_BUDGETS_URL = API_URL + "/listApprovedBudgets";

export const DOWNLOAD_VACANT_UNITS_REPORT_URL =
  API_URL + "/reports/downloadVacantUnitsReport";
export const DOWNLOAD_INCOME_REPORT_URL =
  API_URL + "/reports/downloadIncomeStatement";
export const DOWNLOAD_PETTY_CASH_REPORT_URL =
  API_URL + "/reports/downloadPettyCashReport";
export const DOWNLOAD_BANK_ACCOUNT_CASH_BOOK_REPORT_URL =
  API_URL + "/reports/downloadBankAccountCashBookReport";
export const DOWNLOAD_WORK_ORDER_REPORT_URL =
  API_URL + "/reports/downloadWorkOrderReport";
export const DOWNLOAD_MAINTENANCE_REQUEST_REPORT_URL =
  API_URL + "/reports/downloadMaintenanceRequestReport";
export const DOWNLOAD_EQUIPMENT_MAINTENANCE_REPORT_URL =
  API_URL + "/reports/downloadEquipmentMaintenanceReport";
export const DOWNLOAD_MANAGEMENT_INCOME_REPORT_URL =
  API_URL + "/reports/downloadManagementIncomeStatement";
export const DOWNLOAD_PORTFOLIO_RENT_ROLL_REPORT_URL =
  API_URL + "/reports/downloadPortfolioRentRollReport";
export const DOWNLOAD_COLLECTIONS_REPORT_URL =
  API_URL + "/reports/downloadCollectionsReport";
export const DOWNLOAD_COLLECTIONS_BY_CAPITAL_FUND_REPORT_URL =
  API_URL + "/reports/downloadCollectionsByCapitalFundReport";
export const DOWNLOAD_COLLECTIONS_BY_SERVICE_CHARGE_REPORT_URL =
  API_URL + "/reports/downloadCollectionsByServiceChargeReport";
export const DOWNLOAD_COLLECTIONS_BY_UTILITIES_REPORT_URL =
  API_URL + "/reports/downloadCollectionsByUtilitiesReport";
export const DOWNLOAD_FACILITY_REPORT_URL =
  API_URL + "/reports/downloadFacilityReport";
export const DOWNLOAD_CUSTOMER_BALANCES_REPORT_URL =
  API_URL + "/reports/downloadCustomerBalancesReport";
export const DOWNLOAD_METER_VARIANCE_REPORT_URL =
  API_URL + "/reports/downloadMeterVarianceReport";
export const DOWNLOAD_DAILY_SALES_REPORT_URL =
  API_URL + "/reports/downloadDailySalesReport";
export const DOWNLOAD_CUSTOMER_OUTSTANDING_BALANCES_REPORT_URL =
  API_URL + "/reports/downloadCustomerOutstandingBalancesReport";
export const DOWNLOAD_CLIENT_MOBILE_MONEY_RECONCILIATION_REPORT_URL =
  API_URL + "/reports/downloadCustomerOutstandingBalancesReport";
export const DOWNLOAD_VENDOR_PERFORMANCE_REPORT_URL =
  API_URL + "/reports/downloadVendorPerformanceReport";
export const DOWNLOAD_MAINTENANCE_HISTORY_REPORT_URL =
  API_URL + "/reports/downloadMaintenanceHistoryReport";
export const DOWNLOAD_MAINTENANCE_COST_ANALYSIS_REPORT_URL =
  API_URL + "/reports/downloadMaintenanceCostAnalysisReport";
export const DOWNLOAD_GENERAL_LEDGER_REPORT_URL =
  API_URL + "/reports/downloadGeneralLedgerReport";

//JOURNAL ENTRIES URL ENDPOINTS
export const LIST_ENTRIES_URL = API_URL + "/listJournalEntries?enteredBy=";
export const SAVE_ENTRIES_URL = API_URL + "/saveJournalEntry";
export const REVERSE_ENTRY_URL =
  API_URL + "/reverseJournalEntry?transactionId=";

//PETTY CASH URL ENDPOINTS
export const LIST_PETTY_CASH_ENTRIES_URL = API_URL + "/listPettyCashEntries";
export const SAVE_PETTY_CASH_URL = API_URL + "/savePettyCash";
export const REVERSE_TOPUP_URL = API_URL + "/reverseTopup?transactionId=";

//CUSTOMER URL ENDPOINTS
export const LIST_CUSTOMERS_URL = API_URL + "/listCustomers";
export const FETCH_CUSTOMER_URL = API_URL + "/fetchCustomer?billingId=";
export const SAVE_CUSTOMER_URL = API_URL + "/saveCustomer";
export const UPLOAD_CUSTOMER_DOC_URL =
  API_URL + "/uploadCustomerDocument?meterBillingId=";
export const DELETE_CUSTOMER_DOC_URL =
  API_URL + "/deleteCustomerDocument?meterBillingId=";
export const PENDING_TRANSACTIONS_URL =
  API_URL + "/pendingTransactions?meterBillingId=";
export const SAVE_MAINTENANCE_CUSTOMER_URL =
  API_URL + "/saveMaintenanceCustomer";
export const LIST_MAINTENANCE_CUSTOMERS_URL =
  API_URL + "/listMaintenanceCustomers";
export const CUSTOMER_FINANCIALS_URL =
  API_URL + "/customerFinancials?customerId=";

//LIST URLS
export const COUNTRY_URL = LIST_URL + "/country";
export const FACILITIES_BY_SUBSCRIPTION_URL =
  LIST_URL + "/facilitiesBySubscription?subscriptionId=";
export const FAC_OPT_URL = LIST_URL + "/facilityOptions";
export const ORG_FAC_OPT_URL = LIST_URL + "/organizationFacilityOptions";
export const COMPLEX_OPT_URL = LIST_URL + "/complexOptions";
export const PROXY_FAC_OPT_URL = LIST_URL + "/proxyFacilityOptions";
export const PROXY_FAC_FOR_USER_OPT_URL =
  LIST_URL + "/proxyFacilityForUserOptions";
export const CAP_FUND_OPT_URL = LIST_URL + "/capitalFundsOptions";
export const UNT_OPT_URL = LIST_URL + "/unitOptions?facilityId=";
export const LEASE_UNT_OPT_URL = LIST_URL + "/leaseUnitOptions";
export const ALL_UNIT_URL = LIST_URL + "/allUnitOptions?facilityId=";
export const ORG_ROLES_URL = LIST_URL + "/organizationRoles";
export const USR_ROLES_URL = LIST_URL + "/userRoles";
export const ON_BRD_USR_ROLES_URL = LIST_URL + "/listOnBoardingUserRoles";
export const USR_ADMIN_ROLES = LIST_URL + "/userAdminRoles";
export const PROXY_FAC_URL = LIST_URL + "/proxyFacility";
export const PROXY_ROLE_URL = LIST_URL + "/proxyRole";
export const PROXY_ORG_URL = LIST_URL + "/proxySubscription";
export const ALL_ACCS_URL = LIST_URL + "/allAccounts";
export const ACC_CAT_URL = LIST_URL + "/accountCategories";
export const VEN_CAT_URL = LIST_URL + "/vendorCategories";
export const ACC_SUB_CAT_URL = LIST_URL + "/accountSubCategories";
export const IN_ACC_URL = LIST_URL + "/listIncomeAccounts";
export const EX_ACC_URL = LIST_URL + "/listExpenseAccounts";
export const ASS_ACC_URL = LIST_URL + "/listAssetAccounts";
export const LIB_ACC_URL = LIST_URL + "/listLibilityAccounts";
export const EQ_ACC_URL = LIST_URL + "/listEquityAccounts";
export const MAINT_ACC_URL = LIST_URL + "/listMaintenanceAccount";
export const VEN_URL = LIST_URL + "/listVendors";
export const SIGNUP_USERS_URL = LIST_URL + "/listSignUpUsers";
export const FACILITY_CLIENT_URL = LIST_URL + "/listClientsForFacility";
export const TENANT_URL = LIST_URL + "/listTenants";
export const PAY_MTH_URL = LIST_URL + "/paymentMethods";
export const ALL_PAY_MTH_URL = LIST_URL + "/allPaymentMethods";
export const MT_BILL_PAY_MTH_URL = LIST_URL + "/meterBillingPaymentMethods";
export const LES_PAY_MTH_URL = LIST_URL + "/leasePaymentMethods";
export const OWNERSHIP_PAYMENT_METHOD_URL = LIST_URL + "/titlePaymentMethods";
export const WO_PAY_MTH_URL = LIST_URL + "/workOrderPaymentMethods";
export const ID_TYPE_URL = LIST_URL + "/idTypes";
export const CHG_CD_URL = LIST_URL + "/chargeCodes";
export const MT_BILL_CHG_CD_URL = LIST_URL + "/meterBillingChargeCodes";
export const CHG_CD_STATUS_URL = LIST_URL + "/chargeCodesWithStatus";
export const LES_TYPE_URL = LIST_URL + "/leaseTypes";
export const LES_TER_URL = LIST_URL + "/leaseTerms";
export const PRD_URL = LIST_URL + "/periods";
export const REN_PRD_URL = LIST_URL + "/rentPeriods";
export const SUBSCRIPTION_PRD_URL = LIST_URL + "/subscriptionBillingPeriods";
export const MT_BILL_PRD_URL = LIST_URL + "/meterBillingPeriods";
export const TRF_PRD_URL = LIST_URL + "/transferPeriods";
export const MAINT_PRD_URL = LIST_URL + "/maintenancePeriods";
export const MT_RD_PRD_URL = LIST_URL + "/meterReadingPeriods";
export const RENT_DAY_URL = LIST_URL + "/rentDays";
export const MT_BY_FAC_URL = LIST_URL + "/listMetersByFacility";
export const MT_BY_MT_BILL_URL =
  LIST_URL + "/listMetersByMeterBilling?meterBillingId=";
export const LES_STATUS_URL = LIST_URL + "/leaseStatuses";
export const INV_STATUS_URL = LIST_URL + "/invoiceStatuses";
export const UNIT_TYPE_URL = LIST_URL + "/unitTypes";
export const PROP_MGR_URL = LIST_URL + "/propertyManager";
export const MEM_ORG_URL = LIST_URL + "/memberOrganizations";
export const USR_ORG_URL = LIST_URL + "/userOrganization";
export const BLK_MT_TYPE_URL = LIST_URL + "/bulkWaterMeterType";
export const MT_TYPE_URL = LIST_URL + "/meterType";
export const ASS_MT_TYPE_URL = LIST_URL + "/assetMeterType";
export const RATE_TYPE_URL = LIST_URL + "/rateType";
export const PAY_CRED_ACC_URL = LIST_URL + "/payCreditAccount";
export const DOW_URL = LIST_URL + "/daysOfWeek";
export const ASS_CAT_CD_URL = LIST_URL + "/assetCategoryCode";
export const DEP_TYPE_URL = LIST_URL + "/depreciationType";
export const PRIORITY_URL = LIST_URL + "/priority";
export const ASS_URL = LIST_URL + "/assets";
export const ACT_LES_URL = LIST_URL + "/activeLeases?facilityId=";
export const COUNTRY_BANKS = LIST_URL + "/countryBanks";
export const ACC_BANK_URL = LIST_URL + "/availableAccountsForBank";
export const ORG_BANK_ACC_URL = LIST_URL + "/organizationBankAccounts";
export const LDG_ACC_BANK_URL =
  LIST_URL + "/ledgerAccountsByBank?bankAccountId=";
export const ALL_ORG_BANK_ACC_URL = LIST_URL + "/allBankAccountsForOrg";
export const SUBSCRIPTION_BANK_ACCOUNTS =
  LIST_URL + "/bankAccountsForSubscription";
export const TRF_ORG_BANK_ACC_URL = LIST_URL + "/transferBankAccountsForOrg";
export const RENT_ORG_BANK_ACC_URL = LIST_URL + "/rentBankAccountsForOrg";
export const FAC_USR_URL = LIST_URL + "/listFacilityUsers";
export const MM_PROV_URL = LIST_URL + "/mobileMoneyProviders";
export const UOM_URL = LIST_URL + "/unitOfMeasure";
export const OWNERSHIP_CHARGE_CODES_URL = LIST_URL + "/titleChargeCodes";
export const CHG_ACC_URL = LIST_URL + "/chargeAccounts";
export const CRED_ACC_URL = LIST_URL + "/creditAccounts";
export const LES_UNT_URL = LIST_URL + "/leaseUnits";
export const VAT_ACC_URL = LIST_URL + "/vatAccount";
export const WITHHOLDING_TAX_ACC_URL = LIST_URL + "/withholdingTaxAccount";
export const FAC_COMP_ORG_URL =
  LIST_URL + "/listFacilitiesComplexOrg?complexId=";
export const ACT_COMP_ORG_URL = LIST_URL + "/activeComplexByOrg";
export const EX_ACC_VEN_URL = LIST_URL + "/expenseAccountsByVendor?vendorId=";
export const CSH_PMT_ACC_URL = LIST_URL + "/cashPaymentAccounts";
export const SUB_BILL_FQ_URL = LIST_URL + "/subscriptionBillFrequency";
export const SUB_BILL_DAY_URL = LIST_URL + "/subscriptionBillDays";
export const SUB_BILL_ORG_URL = LIST_URL + "/subsBillingOrganizations";
export const CLT_PMT_MTH_URL = LIST_URL + "/clientPaymentMethods";
export const WO_FOR_QUOTATION_URL = LIST_URL + "/workOrdersForQuotation";
export const ORG_DAYS_OVERDUE_URL = LIST_URL + "/organizationDaysOverdue";
export const SERVICES_URL = LIST_URL + "/listServices";
export const SUB_SER_URL = LIST_URL + "/listSubscriptionServices";
export const MAIN_SUB_SER_URL = LIST_URL + "/listMainSubscriptionServices";
export const FAC_DET_URL = API_URL + "/listFacilityDetails?subscriptionId=";
export const CHK_URL = LIST_URL + "/checkpoints";
export const EMAIL_REPORT_PERIODS_URL = LIST_URL + "/emailReportsPeriods";
export const INSPECTION_TYPES_URL = LIST_URL + "/inspectionTypes";
export const RECOMMENDATIONS_URL = LIST_URL + "/recommendations";
export const INSPECTION_AREAS_URL = LIST_URL + "/inspectionAreas";
export const GET_DEBIT_NOTE_URL = API_URL + "/getDebitNote?debitNoteId=";
export const GET_CREDIT_NOTE_URL = API_URL + "/getCreditNote?creditNoteId=";
export const GET_PAYMENT_URL = API_URL + "/viewPayment?paymentId=";
export const GET_VACATE_NOTICE_URL =
  API_URL + "/viewVacateNotice?vacateNoticeId=";
export const GET_LEASE_CHARGE_URL = API_URL + "/viewLeaseCharge?leaseChargeId=";
export const GET_WORK_ORDER_URL = API_URL + "/fetchWorkOrder?workOrderId=";
export const GET_WORK_ORDER_DETAIL_URL =
  API_URL + "/fetchWorkOrderDetail?workOrderDetailId=";
export const APPROVE_DEBIT_NOTE_URL = API_URL + "/approveDebitNote";
export const REJECT_DEBIT_NOTE_URL = API_URL + "/rejectDebitNote";
export const APPROVE_CREDIT_NOTE_URL = API_URL + "/approveCreditNote";
export const REJECT_CREDIT_NOTE_URL = API_URL + "/rejectCreditNote";
export const UPDATE_LEASE_DEPOSIT_URL = API_URL + "/updateLeaseDeposit";
export const APPROVE_DEPOSIT_CHARGE_URL = API_URL + "/approveLeaseCharge";
export const REJECT_DEPOSIT_CHARGE_URL = API_URL + "/rejectLeaseCharge";
export const LIST_ADMIN_PERSONS_URL = LIST_URL + "/assignees";

//USER URLs
export const LIST_USERS = API_URL + "/listUsers";
export const LIST_TOTAL_USERS = API_URL + "/listTotalUsers";
export const SAVE_USER = API_URL + "/saveUser";

export const SAVE_NEW_CONTACT_PERSON = API_URL + "/saveNewContactPerson";
export const REMOVE_USER = API_URL + "/removeUser";

//Invoice URLs
export const LIST_INVOICE_STATUS = LIST_URL + "/invoiceStatuses";
export const LIST_INVOICES = API_URL + "/listInvoices";
export const LIST_ALL_INVOICES = API_URL + "/listAllInvoices";
export const LIST_TOTAL_INVOICES = API_URL + "/totalInvoices";
export const SAVE_INVOICES = API_URL + "/saveInvoice";
export const DOWNLOAD_INVOICES = API_URL + "/downloadInvoices";
export const EXPORT_INVOICES = API_URL + "/exportInvoices";
export const UPLOAD_INVOICE_DOCUMENT = API_URL + "/uploadInvoiceDocument";
export const INVOICE_OWNERSHIP = API_URL + "/invoiceOwnership";
export const CREDIT_ITEMS = API_URL + "/getCreditItem";
export const INVOICE_LEASE = API_URL + "/invoiceLease";
export const DELETE_INVOICE_DOCUMENT =
  API_URL + "/deleteInvoiceDocument?invoiceId=";
export const SAVE_INVOICE_PAYMENT = API_URL + "/saveInvoicePayment";
export const GET_PERSON_OR_ORG_OUTSTANDING_INV =
  API_URL + "/personOrOrgOutstandingInvoices?customerId=";

//Receipt URLs
export const LIST_RECEIPTS = API_URL + "/listReceipts";
export const TOTAL_RECEIPTS = API_URL + "/totalReceipts";
export const DOWNLOAD_RECEIPTS = API_URL + "/downloadReceipts";
export const EXPORT_RECEIPTS = API_URL + "/exportReceipts";
export const OWNERSHIP_RECEIPTS = API_URL + "/receiptOwnership";
export const LEASE_RECEIPTS = API_URL + "/receiptLease";
export const CHARGE_ITEMS = API_URL + "/getChargeItem";

//ORGANISATION URLS
export const SAVE_ORGANIZATION_URL = API_URL + "/saveOrganization";

//CAPITAL_FUNDS URLs
export const LIST_CAPITAL_FUNDS = API_URL + "/listCapitalFunds";
export const TOTAL_CAPITAL_FUNDS = API_URL + "/totalCapitalFunds";
export const LIST_CAPITAL_FUND_OWNERSHIPS =
  API_URL + "/listCapitalFundOwnerships";
export const EXPORT_CAPITAL_FUNDS = API_URL + "/exportCapitalFunds";
export const DOWNLOAD_CAPITAL_FUNDS = API_URL + "/downloadCapitalFunds";
export const SAVE_CAPITAL_FUND = API_URL + "/saveCapitalFund";
export const UPDATE_CAPITAL_FUND = API_URL + "/updateCapitalFund";
export const DELETE_CAPITAL_FUND = API_URL + "/deleteCapitalFund";

//BUDGETS URLs
export const LIST_BUDGETS = API_URL + "/listBudget";
export const TOTAL_BUDGETS = API_URL + "/totalBudgets";
export const GET_BUDGET = API_URL + "/getBudget?budgetId=";
export const APPROVE_BUDGET = API_URL + "/approveBudget";
export const REJECT_BUDGET = API_URL + "/rejectBudget";
export const DELETE_BUDGET = API_URL + "/deleteBudget";
export const SAVE_BUDGET = API_URL + "/saveBudget";

//SUBSCRIPTION SERVICE URLS
export const LIST_BILLING_SERVICES = API_URL + "/listBillingServices";
export const SAVE_BILLING_SERVICES = API_URL + "/saveBillingService";

//SUBSCRIPTION URLS
export const LIST_SUBSCRIPTIONS = API_URL + "/listSubscriptions";
export const TOTAL_SUBSCRIPTIONS_URL = API_URL + "/totalSubscriptions?name=";
export const LIST_SUBSCRIPTION_LOGS =
  API_URL + "/listSubscriptionLogs?subscriptionId=";
export const TOTAL_SUBSCRIPTION_LOGS_URL =
  API_URL + "/totalSubscriptionLogs?subscriptionId=";
export const SAVE_SUBSCRIPTION = API_URL + "/saveSubscription";
export const FETCH_SUBSCRIPTION = API_URL + "/fetchSubscription";
export const SAVE_SUBSCRIPTION_NOTE = API_URL + "/saveSubscriptionNote";
export const GET_SUBSCRIPTION_NOTES = API_URL + "/subscriptionNotes";
export const OUTSTANDING_SUBSCRIPTION_INVOICES_CHARGES_URL =
  API_URL + "/getOutstandingSubscriptionPayables?subscriptionId=";
export const CLIENT_FINANCIALS_URL =
  API_URL + "/listClientTransactionHistory?subscriptionId=";
export const SAVE_CLIENT_PAYMENT = API_URL + "/saveClientPayment";
export const DELETE_PROPERTY = API_URL + "/deleteProperty";
export const UPLOAD_SUBSCRIPTION_DOC_URL =
  API_URL + "/uploadSubscriptionDocument?subscriptionId=";
export const DELETE_SUBSCRIPTION_DOC_URL =
  API_URL + "/deleteSubscriptionDocument?subscriptionId=";
export const SAVE_CLIENT_CREDIT = API_URL + "/saveClientCredit";
export const SAVE_CLIENT_DEBIT = API_URL + "/saveClientCharge";
//VENDORS_URLs
export const LOAD_VENDORS = API_URL + "/listVendors?name=";
export const TOTAL_VENDORS = API_URL + "/totalVendors?name=";
export const SAVE_VENDOR = API_URL + "/saveVendor";
export const FETCH_VENDORS = API_URL + "/fetchVendor?vendorId=";
export const DELETE_VENDOR = API_URL + "/deleteVendor";
export const BULK_UPLOAD_VENDORS = API_URL + "/processVendorBulkUpload";

//TENANTS_URLs
export const LIST_TENANTS = API_URL + "/listTenant?tenant=";
export const TOTAL_TENANTS = API_URL + "/totalTenants?tenant=";
export const EXPORT_TENANTS = API_URL + "/exportTenants?tenantType=";
export const EXPORT_ACCOUNT_HOLDERS = API_URL + "/exportAccountHolders";
export const SAVE_TENANT = API_URL + "/saveTenant";

//OWNERS_URLs
export const LOAD_OWNERS = API_URL + "/listOwner?name=";
export const TOTAL_OWNERS = API_URL + "/totalOwners?name=";
export const SAVE_OWNERS = API_URL + "/saveOwner";

//BANK_ACCOUNTS_URLs
export const LOAD_BANK_ACCOUNTS = API_URL + "/listBanks?pageNo=";
export const TOTAL_BANK_ACCOUNTS = API_URL + "/totalBanks";
export const SAVE_BANK_ACCOUNT = API_URL + "/saveBankAccount";
export const DELETE_BANK_ACCOUNT = API_URL + "/deleteBankAccount";
export const LIST_LINKED_FACILITIES =
  API_URL + "/listLinkedFacilities?bankAccountId=";

//MOBILE_MONEY_RECONCILIATION_URLs
export const MOBILE_MONEY_RECONCILIATIONS =
  API_URL + "/mpesaReconciliations?pageNo=";
export const TOTAL_MOBILE_MONEY_RECONCILIATIONS =
  API_URL + "/totalMpesaReconciliations";
export const RECONCILE_MOBILE_MONEY_URL = API_URL + "/reconcileMobileMoney";
export const DOES_CLIENT_HAVE_MPESA2_FACILITIES_URL =
  API_URL + "/doesClientHaveMpesa2Facilities";

//MOBILE_MONEY_TRANSFER_URLs
export const LIST_MOBILE_MONEY_TRANSFERS = API_URL + "/listTransfers";
export const TOTAL_MOBILE_MONEY_TRANSFERS = API_URL + "/totalTransfers";
export const FACILITY_AMOUNT_URL = API_URL + "/getFacilityAmounts";
export const SAVE_TRANSFER_URL = API_URL + "/saveTransfer";
export const VIEW_TRANSFER_URL = API_URL + "/viewTransfer?transferId=";
export const UPLOAD_TRANSFER_DOCUMENTS_URL =
  API_URL + "/uploadTransferDocument?transferId=";
export const DELETE_TRANSFER_DOCUMENTS_URL =
  API_URL + "/deleteTransferDocument";

//PROFILE URLs
export const GET_LOGGED_IN_USER_URL = API_URL + "/getLoggedInUser";
export const UPDATE_USER_URL = API_URL + "/updateUserDetails";
export const GET_LOGGED_IN_USER_NOTIFICATIONS_URL =
  API_URL + "/getLoggedInUserNotifications";
export const CHANGE_NOTIFICATION_URL = API_URL + "/changeNotification";
export const GET_LOGGED_IN_USER_EMAIL_NOTIFICATIONS_URL =
  API_URL + "/getEmailNotificationSettingsForUser";
export const UPDATE_EMAIL_REPORT_NOTIFICATION_URL =
  API_URL + "/updateEmailReportNotification";
export const FETCH_ORGANIZATION_URL =
  API_URL + "/fetchOrganization?organizationId=";
export const FETCH_SUBSCRIBER_URL = API_URL + "/fetchSubscriber?personOrgId=";
export const GENERATE_ACCOUNTS_URL = API_URL + "/subscriptionAccount";
export const UPLOAD_ORG_LOGO_URL = API_URL + "/uploadOrgLogo?id=";
export const GET_SUBSCRIPTION_DAYS_OVERDUE =
  LIST_URL + "/subscriptionDaysOverdue ";

//MOBILE_MONEY_SUMMARY_URLs
export const LIST_MOBILE_MONEY_CLIENTS =
  API_URL + "/listMobileMoneyClients?facilityName=";
export const TOTAL_MOBILE_MONEY_CLIENTS =
  API_URL + "/totalMobileMoneyClients?facilityName=";
export const MOBILE_MONEY_SUB_LIST = API_URL + "/getSubscriptionList";
export const EDIT_TRANSFER_FREQUENCY = API_URL + "/editTransferFrequency";
export const VIEW_MOBILE_MONEY_URL =
  API_URL + "/viewMobileMoneyClient?facilityId=";
export const UPLOAD_MOBILE_MONEY_DOCUMENTS_URL =
  API_URL + "/uploadMpesaDocument?facilityId=";
export const EXPORT_MOBILE_MONEY_CLIENTS =
  API_URL + "/exportMobileMoneyClients";
export const DOWNLOAD_MOBILE_MONEY_CLIENTS =
  API_URL + "/downloadMobileMoneyClients";
// export const MOBILE_MONEY_FACILITY_LIST_BY_SUB = API_URL + '/getFacilityList?subscriberId='
export const DELETE_MPESA_DOC = API_URL + "/deleteMpesaDocument?facilityId=";

//ACCOUNTS_URLs
export const LIST_ACCOUNTS = API_URL + "/listAccounts";
export const TOTAL_ACCOUNTS = API_URL + "/totalAccounts";
export const SAVE_ACCOUNT = API_URL + "/saveAccount";

//RECONCILIATIONS_URLs
export const LIST_RECONCILIATIONS =
  API_URL + "/listReconciliations?accountName=";
export const TOTAL_RECONCILIATIONS =
  API_URL + "/totalReconciliations?accountName=";
export const RECONCILIATIONS_START_DATE =
  API_URL + "/getReconciliationStartDate?accountId=";
export const BANK_ACC_TRANSACTION_DTLS_NOT_RECONCILED_BEFORE_URL =
  API_URL + "/bankAccountTransactionDetailsNotReconciledBefore?accountId=";
export const SAVE_RECONCILIATION = API_URL + "/saveReconciliation";
export const LIST_RECONCILED_TRANSACTION_DETAILS =
  API_URL +
  "/listReconciledTransactionDetailsForReconciliation?reconciliationId=";

//PRINT_URLs
export const PRINT_TRANSFER_URL =
  PRINT_URL + "/printClientPaymentVoucher?clientPaymentVoucherId=";
export const PRINT_CLIENT_STATEMENT_URL =
  PRINT_URL + "/clientBillingStatement?subscriptionId=";
export const PRINT_CAPITAL_FUNDS =
  PRINT_URL + "/capitalFundDetails?capitalFundId=";
export const PRINT_OWNERSHIP_STATEMENT_URL =
  PRINT_URL + "/titleStatement?titleId=";
export const PRINT_OWNERSHIP_URL = PRINT_URL + "/titleDetails?titleId=";
export const PRINT_WORK_ORDER_LPO_DOCUMENT_URL =
  PRINT_URL + "/printVendorLPO?workOrderId=";
export const PRINT_WORK_ORDER_URL =
  PRINT_URL + "/printWorkOrderDetails?workOrderId=";
export const PRINT_LEASE_STATEMENT_URL = PRINT_URL + "/leaseStatement?leaseId=";
export const PRINT_LEASE_URL = PRINT_URL + "/leaseDetails?leaseId=";
export const PRINT_INVOICE = PRINT_URL + "/invoice?invoiceId=";
export const PRINT_CLIENT_INVOICE_URL = PRINT_URL + "/clientInvoice?invoiceId=";
export const PRINT_CLIENT_RECEIPT_URL = PRINT_URL + "/clientReceipt?receiptId=";
export const PRINT_RECEIPT_URL = PRINT_URL + "/receipt?receiptId=";
export const PRINT_CREDIT_URL = PRINT_URL + "/credit?creditId=";
export const PRINT_CLIENT_CREDIT_URL = PRINT_URL + "/clientCredit?creditId=";
export const PRINT_CLIENT_CHARGE_URL = PRINT_URL + "/clientCharge?chargeId=";
export const PRINT_CHARGE_URL = PRINT_URL + "/charge?chargeId=";
export const PRINT_BILL_URL = PRINT_URL + "/bill?billId=";
export const PRINT_PAYMENT_VOUCHER_URL =
  PRINT_URL + "/printPaymentVoucher?paymentVoucherId=";
export const PRINT_RECONCILIATION_URL =
  PRINT_URL + "/bankReconciliation?reconciliationId=";
export const PRINT_METER_BILLING_STATEMENT_URL =
  PRINT_URL + "/printMeterBillingStatement?meterBillingId=";
export const PRINT_METER_BILLING_URL =
  PRINT_URL + "/printMeterBillingDetails?meterBillingId=";
export const PRINT_CUSTOMER_SALES_STATEMENT_URL =
  PRINT_URL + "/printCustomerSalesStatement?billingId=";

//CREDIT NOTES URLs
export const LIST_CREDIT_NOTES = API_URL + "/listCreditNotes";
export const GET_TOTAL_CREDIT_NOTES = API_URL + "/getTotalCreditNotes";
export const EXPORT_CREDIT_NOTES = API_URL + "/downloadCreditNotes";
export const VIEW_UTILITY_IMAGE_URL =
  API_URL + "/utilityReadingImage?filename=";

//DEBIT_NOTES URLs
export const LIST_DEBIT_NOTES_URL = API_URL + "/listDebitNotes";
export const GET_TOTAL_DEBIT_NOTES_URL = API_URL + "/totalDebitNotes";
export const EXPORT_DEBIT_NOTES_URL = API_URL + "/downloadDebitNotes";

export const PROPERTY_BULK_UPLOAD_TEMPLATE_URL =
  API_URL + "/getPropertBulkUploadTemplate?subscriptionType=";

// LISTINGS_URLs
export const LISTINGS_SIGNUP_URL = LISTINGS_URL + "/register";
export const LISTINGS_LOGIN_URL = LISTINGS_URL + "/login";
export const LISTINGS_LOGOUT_URL = LISTINGS_URL + "/logout";
export const LIST_LISTING_TYPES_URL = LISTINGS_LIST_URL + "/listingTypes";
export const LIST_PROPERTY_TYPES_URL = LISTINGS_LIST_URL + "/listPropertyTypes";
export const SAVE_LISTINGS_URL = LISTINGS_URL + "/saveListing";
export const GET_LISTINGS_URL = LISTINGS_URL + "/getListingsUnsecure";
export const GET_LISTINGS_SECURE_URL = LISTINGS_URL + "/getListingsSecure";
export const GET_LISTINGS_BY_PERSON_ID_URL =
  LISTINGS_URL + "/getListingsByPersonId";
export const GET_TOTAL_LISTINGS_URL = LISTINGS_URL + "/getTotalListings";
export const GET_LISTING_IMAGE_URL = LISTINGS_URL + "/getImage";
export const SAVE_LISTINGS_APPLICATION_URL =
  LISTINGS_URL + "/saveListingApplication";
export const GET_APPLICATIONS_URL = LISTINGS_URL + "/getApplications";
export const GET_APPLICATIONS_NOTE_URL =
  LISTINGS_URL + "/getListingApplicationNotes";
export const SAVE_FAVORITE_LISTING_URL = LISTINGS_URL + "/saveFavoriteListing";
export const GET_FAVORITE_LISTINGS_URL = LISTINGS_URL + "/getPersonFavorites";
export const FETCH_FACEBOOK_USER_URL = LISTINGS_URL + "/findFacebookUser";
export const FETCH_GOOGLE_USER_URL = LISTINGS_URL + "/findGoogleUser";
export const SOCIAL_REGISTRATION_URL = LISTINGS_URL + "/socialRegistration";
export const UPLOAD_LISTING_DOCUMENT_URL =
  LISTINGS_URL + "/uploadListingDocument?listingId=";
export const DELETE_LISTING_DOCUMENT_URL =
  LISTINGS_URL + "/deleteListingDocument?listingId=";
export const DELETE_LISTING_URL = LISTINGS_URL + "/deleteListing?listingId=";
export const LIST_USER_FACILITIES_URL = LISTINGS_LIST_URL + "/facilities";
export const LIST_FACILITY_UNITS_URL =
  LISTINGS_LIST_URL + "/facilityUnits?facilityId=";
export const GET_UNIT_PROPERTIES_URL =
  LISTINGS_URL + "/getUnitProperties?unitId=";
export const SAVE_APPOINTMENT_URL = LISTINGS_URL + "/saveAppointment";
export const GET_APPOINTMENTS_URL = LISTINGS_URL + "/listAppointments?date=";
export const USER_APPOINTMENT_BY_LISTING_URL =
  LISTINGS_URL + "/userAppointmentByListingId?listingId=";
export const APPOINTMENTS_BY_LISTING_URL =
  LISTINGS_URL + "/appointmentsByListing?listingId=";
export const CANCEL_TOUR_BOOKING_URL =
  LISTINGS_URL + "/cancelBookingById?bookingId=";

// SUPPORT TICKETS
export const CREATE_SUPPORT_TICKET = API_URL + "/support/createTicket";

// TEST URL ENDPOINTS
export const TEST_CREDIT_TRANSFER_URL =
  TEST_URL + "/testCreditTransfer?reference=";
export const TEST_TRANSACTION_QUERY_URL =
  TEST_URL + "/testTransactionQuery?reference=";
