/**
 * This contains all the functions that are used in the
 * view across the application
 *
 */

import moment from "moment"
import {issueWarnMessage} from "../actions/message";
/*import $ from 'jquery'

const standardDate = {
    year: "numeric", month: "short", weekday:'short',
    day: "2-digit"
}*/

const shortDate = {
    year: "numeric", month: "2-digit",
    day: "2-digit"
}

/*function showSpinner() {
    $('#spinner').addClass('active')
}

function hideSpinner() {
    $('#spinner').removeClass('active')
}*/

//
export const formatDateMonthDay = (dateValue) => {
    let monthYearOptions = {
        year: "numeric", month: "2-digit"
    }
    return new Date(dateValue).toLocaleTimeString("en-US", monthYearOptions)
}

//default param is all options
export const formatDate = (dateValue) => {
    return new Date(dateValue).toLocaleDateString("en-GB", shortDate)
}

//default param is all options
export const formatURLDate = (dateValue) => {
    return new Date(dateValue).toLocaleDateString("es-CL", shortDate)
}

/**
 * Add days to a date
 * @param date
 * @param days
 * @returns {Date}
 */
export const addDays = (date, days) => {
    date.setDate(date.getDate() + days)
    return date
}

export const addMinutes = (date, minutes) => {
    date.setMinutes(date.getMinutes() + minutes)
    return date
}

/**
 * @param dateValue
 * @param adjustTimezone if true allows adds/subtracts from time
 * @returns {string}
 */
export const formatDateTime = (dateValue, adjustTimezone = false) => {
    const allAvailableOptions = {
        year: "numeric", month: "short", weekday: 'short',
        day: "2-digit", hour: "numeric", minute: "2-digit"
    }
    if (adjustTimezone === true) {
        return adjustForTimezone(new Date(dateValue)).toLocaleTimeString("en-US", allAvailableOptions)
    }
    return new Date(dateValue).toLocaleTimeString("en-US", allAvailableOptions)

}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

const dateFormats = ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'DD/MM/YYYY HH:mm:ss', moment.ISO_8601]

export const getMonthNameFromDate = (date) => {
    if (!isValidDate(date)) {
        return ""
    }
    const monthNo = date.getMonth()
    if (monthNo > -1 && monthNo < monthNames.length) {
        return monthNames[monthNo]
    }
    return ""
}

const numberSuffixes = {
    1: "st", 2: "nd", 3: "rd", 4: "th", 5: "th", 6: "th", 7: "th", 8: "th", 9: "th", 10: "th",
    11: "th", 12: "th", 13: "th", 14: "th", 15: "th", 16: "th", 17: "th", 18: "th", 19: "th", 20: "th",
    21: "st", 22: "nd", 23: "rd", 24: "th", 25: "th", 26: "th", 27: "th", 28: "th", 29: "th", 30: "th", 31: "st"
}

export const getNumberSuffix = (no) => {
    return numberSuffixes[tryParseInt(no, -1)] || ""
}

export const roundTo = (num, to = 2) => {
    return +(Math.round(num + ("e+" + to)) + ("e-" + to))
}


export const isDate = (aVal) => {
    return moment(aVal, dateFormats).isValid()
}


export const getMoment = (aVal) => {
    return moment(aVal, dateFormats)
}

export const getDateString = (task, date) => {
    if (isValidDate(date)) {
        return date.toLocaleDateString()
    } else if (task === "Create") {
        return ""
    } else {
        return new Date(date).toLocaleDateString()
    }
}

export const titleCase = (str) => {
    if (!isEmptyString(str)) {
        let returnStr = str.toLowerCase().split(' ')
        for (let i = 0; i < returnStr.length; i++) {
            returnStr[i] = returnStr[i].charAt(0).toUpperCase() + returnStr[i].slice(1)
        }
        return returnStr.join(' ')
    } else {
        return str
    }

}

//TODO: IMPLEMENT HANDLING FOR NUMBERS AND STRINGS
export const sortJSONArray = (sortField, asc = true, children = 'children') => {
    return function (a, b) {
        // let aVal = eval('a.'+sortField )
        // let bVal = eval('b.'+sortField )
        let aVal = a[sortField]
        let bVal = b[sortField]

        //recursively check for 'children'
        if (!isEmptyArray(aVal[children])) {
            return aVal[children].sort(sortJSONArray(sortField))
        }
        if (!isEmptyArray(bVal[children])) {
            return bVal[children].sort(sortJSONArray(sortField))
        }
        if (isDate(aVal)) {
            //sort dates
            return sortDates(aVal, bVal, asc)
        } else if (isNumeric(aVal)) {
            //sort numeric
            return sortNumeric(aVal, bVal, asc)
        } else if (isAlphaNum(aVal)) {
            //special handling for strings that have alpha numeric
            // and special Characters
            return sortAlphaNum(aVal, bVal)
        } else {
            // console.log('val in a ', eval('a.'+sortField ))
            // console.log('val in b ', eval('a.'+sortField ))
            if ((asc && (aVal > bVal))
                || (!asc && (aVal < bVal))) {
                return 1
            } else if ((asc && (aVal < bVal))
                || (!asc && (aVal > bVal))) {
                return -1
            }
            return 0
        }

    }
}


export const isNumeric = (aVal) => {
    if (isNullUndefined(aVal) || isEmptyString(aVal))
        return false
    else if (isNaN(aVal) === false) {
        return true
    } else {
        //special handling for strings like "5,000" that fail the test above
        return isNaN(aVal.replace(',', '')) === false
    }

}


export const sortNumeric = (aVal, bVal, asc) => {
    if (asc) {
        return aVal.replace(',', '') - bVal.replace(',', '')
    } else if (!asc) {
        return bVal.replace(',', '') - aVal.replace(',', '')
    } else {
        return 0
    }
}


export const sortDates = (aVal, bVal, asc) => {
    if (asc) {
        return getMoment(aVal).diff(getMoment(bVal))
    } else if (!asc) {
        return getMoment(bVal).diff(getMoment(aVal))
    } else {
        return 0
    }
}

export const isAlphaNum = (aVal) => {
    let reAlphaNum = /[a-z0-9A-Z]+/g
    return reAlphaNum.test(aVal)
}


export const isAlphaNumSpecial = (aVal) => {
    let reSpecial = /[/-]+/g
    let reAlphaNum = /[a-z0-9A-Z]+/g
    return reAlphaNum.test(aVal) && reSpecial.test(aVal)
}


export const sortAlphaNum = (a, b) => {
    let reA = /[^a-zA-Z]/g
    let reN = /[^0-9]/g
    if (isNullUndefined(a)) {
        a = ""
    }
    if (isNullUndefined(b)) {
        b = ""
    }
    let aA = a.replace(reA, "")
    let bA = b.replace(reA, "")

    if (aA === bA) {
        let aN = parseInt(a.replace(reN, ""), 10)
        let bN = parseInt(b.replace(reN, ""), 10)
        return aN === bN ? 0 : aN > bN ? 1 : -1
    } else {
        return aA > bA ? 1 : -1
    }

}

export const formatCurrency = (value) => {
    if (isEmptyString(value)) {
        return "0"
    } else if ("---" === value) {
        return value
    } else {
        return new Intl.NumberFormat("en", {minimumFractionDigits: 2}).format(value)
    }
}

export const adjustToTimezone = (value) => {
    //get date from timestamp
    let date = new Date(value)
    let timeOffsetInMS = date.getTimezoneOffset() * 60000
    date.setTime(date.getTime() - timeOffsetInMS)
    const allAvailableOptions = {
        year: "numeric", month: "short", weekday: 'short',
        day: "2-digit", hour: "numeric", minute: "2-digit"
    }
    // console.log("adjDate",date)
    return (date).toLocaleTimeString("en-US", allAvailableOptions)
}

export const stringToFloat = (value) => {
    if (isEmptyString(value)) {
        return "0"
    } else {
        return parseFloat(value.replace(/,/g, ''))
    }
}


export const currencyToFloat = (value) => {
    if (isEmptyString(value)) {
        return 0
    } else {
        return parseFloat(value.replace(/[^\d.]/g, '')).toFixed(2)
    }
}

/**
 * Given a id string for a html element, will scroll till that element
 * fills the viewport
 * @param id e.g. "myForm" or "my-form"
 */
export const scrollToId = (id) => {
    if (!isNullUndefined(id) && (typeof id === "string")) {
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        }
    }
}

export const getLeaseRefNumber = () => {
    let text = "SK"
    let charset = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789"
    for (let i = 0; i < 5; i++) {
        text += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return text
}

export const getBillingRefNumber = () => {
    let text = "SB"
    let charset = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789"
    for (let i = 0; i < 5; i++) {
        text += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return text
}

export const getTitleRefNumber = () => {
    let text = "SY"
    let charset = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789"
    for (let i = 0; i < 5; i++) {
        text += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return text
}

export const getRefNumber = () => {
    let text = ""
    let charset = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789"
    for (let i = 0; i < 8; i++) {
        text += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return text
}

export const getIdRefNumber = () => {
    let text = ""
    let charset = "abcdefghijklmnopqrstuvwxyz0123456789"
    for (let i = 0; i < 32; i++) {
        text += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return text
}

export const isEmptyString = (value) => {
    if (isNullUndefined(value)) {
        return true
    } else if (typeof value === 'string' || value instanceof String) {
        return value.trim() === ""
    } else {
        return false
    }
}

export const isEmptyObject = (obj) => {
    return isNullUndefined(obj) || Object.keys(obj).length === 0
}

export const isNullUndefined = (value) => {
    return value === null || typeof value === "undefined"
}

export const getCenterFromBounds = (bounds) => {
    const latLng = {lat: 0, lng: 0}
    if (bounds.east < 0 && bounds.west < 0) {
        //western hemisphere
        latLng.lng = bounds.east - (Math.abs(bounds.west) - Math.abs(bounds.east)) / 2
    } else if (bounds.east > 0 && bounds.west > 0) {
        //eastern hemisphere
        latLng.lng = bounds.west - (bounds.east - bounds.west) / 2
    } else {
        //east - west split
        latLng.lng = bounds.east - (bounds.east + Math.abs(bounds.west)) / 2
    }

    if (bounds.north < 0 && bounds.south < 0) {
        //southern hemisphere
        latLng.lat = bounds.north - (Math.abs(bounds.south) - Math.abs(bounds.north)) / 2
    } else if (bounds.north > 0 && bounds.south > 0) {
        //nothern hemisphere
        latLng.lat = bounds.south + (bounds.north - bounds.south) / 2
    } else {
        //north-south split
        latLng.lat = bounds.north - (bounds.north + Math.abs(bounds.south)) / 2
    }
    return latLng
}

export const adjustForTimezone = (date) => {
    let timeOffsetInMS = date.getTimezoneOffset() * 60000
    date.setTime(date.getTime() - timeOffsetInMS)
    return date
}

export const dateDifference = (d1str, d2str) => {
    let d1 = new Date(d1str)
    let d2 = new Date(d2str)
    return (d2.getTime() - d1.getTime()) / 1000 / 60 / 60 / 24 // diff in days
}

/**
 * Given a param, ensure it is an array and has no objects
 * @param array object
 * @returns {boolean} true if empty and array
 */
export const isEmptyArray = (array) => {
    try {
        if (isNullUndefined(array)) {
            return true
        } else {
            return !(array instanceof Array && array.length > 0)
        }
    } catch (err) {
        return true
    }
}

export const getVATAmount = (amount) => {
    if (isNaN(amount)) {
        issueWarnMessage("Only numeric amounts are allowed for VAT calculation")
        return 0
    } else {
        return (amount * 0.16).toFixed(2)
    }
}

export const secureId = (idToSecure) => {
    if (isEmptyString(idToSecure)) {
        return ''
    }
    let cutoff = idToSecure.length / 2
    let result = ''
    for (let i = 0; i < cutoff; i++) {
        result = result + '*'
    }
    result = result + idToSecure.substr(cutoff, idToSecure.length)
    return result
}

export const getURLOrigin = () => {
    let domain = '' + window.location.href
    domain = domain.trim().substring(domain.indexOf('/') + 2)
    domain = domain.substring(0, domain.indexOf('/'))
    // console.log('domain: ', domain)//log
    return domain
}

/**
 * Type checking to ensure the parameter is an Array
 * @param arr
 * @returns {boolean}
 */
export const isArray = (arr) => {
    return (arr instanceof Array)
}

export const downloadTextToFile = (filename, text) => {
    let pom = document.createElement('a')
    text = text.replace(/\n/g, "\r\n")

    pom.setAttribute('href', 'data:text/plaincharset=utf-8,' + text)
    pom.setAttribute('download', filename)

    if (document.createEvent) {
        let event = document.createEvent('MouseEvents')
        event.initEvent('click', true, true)
        pom.dispatchEvent(event)
    } else {
        pom.click()
    }
}

export const downloadBlobToFile = (filename, blob) => {
    let pom = document.createElement('a')
    let href = URL.createObjectURL(blob)
    pom.setAttribute('href', href)
    pom.setAttribute('download', filename)

    if (document.createEvent) {
        let event = document.createEvent('MouseEvents')
        event.initEvent('click', true, true)
        pom.dispatchEvent(event)
    } else {
        pom.click()
    }
}

//
// export const isSysAdmin(){
//     return identity.roles.indexOf('SYSAD') >= 0
// }
//
//
// export const isBuildDir(){
//     return identity.proxyRole &&  identity.proxyRole === 'BLDIR'
// }

/**
 * Given a string try get the float value within the string
 * else return the default value
 * @param str - string with float inside
 * @param defaultValue - value returned on error (e.g. unable to parse)
 * @returns {*} float
 */
export const tryParseFloat = (str, defaultValue) => {
    let retValue = defaultValue
    if (!isNullUndefined(str)) {
        try {
            if (isNumeric(str)) {  // passed in an actual number instead of str so can still be returned as right
                retValue = parseFloat(str)
            } else if (str.length > 0) {
                if (!isNaN(str)) {
                    retValue = parseFloat(str)
                }
            }
        } catch (e) {
        }
    }
    return retValue
}

/**
 * Given a string try get the date value within the string
 * else return the default value
 * @param str - string with date inside
 * @param defaultValue - value returned on error (e.g. unable to parse)
 * @returns {*} Date
 */
export const tryParseDate = (str, defaultValue) => {
    let retValue = defaultValue
    try {
        retValue = new Date(str)
    } catch (e) {
    }
    return retValue
}

/**
 * Try get an int value from a string else return the default value
 * @param str - string with int inside
 * @param defaultValue - value returned on error (e.g. unable to parse)
 * @returns {*} int
 */
export const tryParseInt = (str, defaultValue) => {
    let retValue = defaultValue
    if (!isNullUndefined(str)) {
        try {
            if (isNumeric(str)) {  // passed in an actual number instead of str so can still be returned as right
                retValue = parseInt(str)  // need to ensure it is only a int, not a float
            } else if (str.length > 0) {
                if (!isNaN(str)) {
                    retValue = parseInt(str)
                }
            }
        } catch (e) {
        }
    }
    return retValue
}

/**
 * Checks if object is a valid Date object (i.e. no Date("hello"))
 * Checks if object is a valid Date object (i.e. no Date("hello"))
 * @param date Any object
 * @returns {boolean} true/false
 */
export const isValidDate = (date) => {
    return (date != null) && (date instanceof Date) && !isNaN(date)
}

export const isAfterToday = (date) => {
    return new Date(date).valueOf() > new Date().valueOf()
}

export const isWebSocketSupported = () => {
    return 'WebSocket' in window || 'MozWebSocket' in window
}

export const isString = (val) => {
    return typeof val === 'string' || val instanceof String;

}

export const isFunction = (func) => {
    return func && {}.toString.call(func) === '[object Function]'
}

export const getDayStart = (date) => {
    let tempDate = new Date(date.toString())
    tempDate.setHours(0, 0, 0, 0)
    return tempDate
}

export const getDayEnd = (date) => {
    let tempDate = new Date(date.toString())
    tempDate.setHours(23, 59, 59, 999)
    return tempDate
}

export const bytesToMegabytes = (bytes) => {
    let kilobytes = bytes / 1000
    let megabytes = kilobytes / 1000
    return megabytes
}

export const shortenString = (str, length, suffix = "...") => {
    if (str.length < length) {
        return str
    }
    return `${str.substring(0, (length - suffix.length))}${suffix}`
}

export const maskPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
        return phoneNumber.replace(/^(\d{4})(\d{3})(\d{3})$/, '$1***$3')
    }
    return phoneNumber
}
