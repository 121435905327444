import React, { useCallback, useEffect, useState } from "react";
import {
  MainContainer,
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { ResponsiveRow } from "../constants/layout.constants";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import kodi_theme from "../themes/kodiThemeProvider";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import { MoreVert } from "@mui/icons-material";
import PendingTaxPaymentModal from "../components/modals/pendingTaxPaymentModal";
import { DatePickerInput } from "../components/inputs/datePickerInput";

function DataGridDropdown({ params }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div key={params.row.id}>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: kodi_theme.palette.background.paper,
          padding: "10px",
        }}
      >
        <MoreVert sx={{ color: kodi_theme.palette.primary.dark }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          // onClick={handleOpenTaxPaymentModal}
          disabled={params.row.status === "Paid"}
          sx={{
            color: kodi_theme.palette.primary.dark,
            fontWeight: 800,
            fontSize: ".9rem",
          }}
        >
          View Voucher
        </MenuItem>{" "}
        <MenuItem
          // onClick={handleOpenTaxPaymentModal}
          disabled={true}
          sx={{
            color: kodi_theme.palette.primary.dark,
            fontWeight: 800,
            fontSize: ".9rem",
          }}
        >
          Void Voucher
        </MenuItem>
      </Menu>
    </div>
  );
}

const taxesDataGridColumns = [
  {
    field: "voucherNumber",
    headerName: "Voucher Number",
    width: 200,
  },
  {
    field: "voucherDate",
    headerName: "Voucher Date",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "taxType",
    headerName: "Tax Type ",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Payment Status",
    minWidth: 200,
    flex: 1,
  },
  {
    // field: "actions",
    headerName: "Actions",
    minWidth: 90,
    flex: 0.45,
    renderCell: (params) => <DataGridDropdown params={params} />,
  },
];

export default function Taxes() {
  const [taxRows, setTaxRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pendingVATTaxes, setPendingVATTaxes] = useState("--");
  const [pendingWithholdingTax, setPendingWithholdingTax] = useState("--");

  const [modalOpen, setModalOpen] = useState(false);

  function handleProcessTaxPayment({ newPaymentObj }) {
    setModalOpen(false);
  }

  function handleOpenTaxPaymentModal() {
    setModalOpen(true);
  }

  function handleTaxFilterByDate() {}

  const fetchData = useCallback(async function () {
    setIsLoading(true);

    setTimeout(() => {
      setPendingVATTaxes("20,000.21 KES");
      setPendingWithholdingTax("17,908.35 KES");

      setTaxRows([
        {
          id: 0,
          voucherNumber: "579DJEPW",
          voucherDate: "10/08/2024",
          taxType: "Withholding",
          amount: "12,100.28 KES",
          status: "Not Paid",
        },
        {
          id: 1,
          voucherNumber: "1342DWHT",
          voucherDate: "10/08/2024",
          taxType: "VAT",
          amount: "4,131.28 KES",
          status: "Not Paid",
        },
        {
          id: 2,
          voucherNumber: "4321DQ",
          voucherDate: "08/09/2024",
          taxType: "Withholding",
          amount: "14,170.28 KES",
          status: "Paid",
        },
        {
          id: 3,
          voucherNumber: "123YTRD",
          voucherDate: "08/09/2024",
          taxType: "VAT",
          amount: "5,170.28 KES",
          status: "Paid",
        },
      ]);
      setIsLoading(false);
    }, 2200);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MainContainer
      sx={{
        width: "100%",
        height: "auto",

        flexWrap: "nowrap",
        flexDirection: "column",

        justifyContent: "flex-start",
        alignItems: "flex-start",

        padding: 0,
        paddingBottom: "40px",
        marginBottom: "40px",
      }}
    >
      <ResponsiveRow
        item
        sx={{
          width: { xs: "100%", lg: "100%" },
          flexWrap: { xs: "wrap", lg: "nowrap" },
          justifyContent: "space-between",
          gap: "10px",
          padding: 0,
          margin: "40px auto 20px auto",
        }}
      >
        <ResponsiveRow
          sx={{
            flexDirection: "column",
            width: {
              xs: "100%",
              lg: "50%",
            },

            alignItems: "flex-start",
            justifyContent: "space-between",
            backgroundColor: kodi_theme.palette.common.white,
            borderRadius: "10px",

            gap: "20px",
            padding: "20px",
            boxShadow: "4px 4px 12px #aaa",
          }}
        >
          <Typography
            width="100%"
            textAlign="start"
            color="black"
            fontWeight={900}
            variant="h1"
          >
            {pendingVATTaxes}
          </Typography>
          <Typography
            width="100%"
            textAlign="start"
            color="primary.dark"
            fontWeight={900}
            variant="h6"
          >
            Pending VAT Taxes Amount
          </Typography>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            flexDirection: "column",
            width: {
              xs: "100%",
              lg: "50%",
            },

            alignItems: "flex-start",
            justifyContent: "space-between",
            backgroundColor: kodi_theme.palette.common.white,
            borderRadius: "10px",

            gap: "20px",
            padding: "20px",
            boxShadow: "4px 4px 12px #aaa",
          }}
        >
          <Typography
            width="100%"
            textAlign="start"
            color="black"
            fontWeight={900}
            variant="h1"
          >
            {pendingWithholdingTax}
          </Typography>
          <Typography
            width="100%"
            textAlign="start"
            color="primary.dark"
            fontWeight={900}
            variant="h6"
          >
            Pending Withholding Tax Amount
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>
      <TopBarContainer
        sx={{
          justifyContent: { xs: "center", lg: "space-between" },
          width: { xs: "100%", lg: "90%" },
          marginBottom: "20px",
          minHeight: "80px",
        }}
        container
      >
        <ResponsiveRow
          item
          sx={{ width: { xs: "100%", lg: "50%" }, padding: 0 }}
        >
          <Typography
            variant="h5"
            sx={{
              width: "100%",
              height: "auto",

              textAlign: { xs: "center", lg: "start" },
            }}
            fontWeight={"black"}
          >
            Taxes Payments
          </Typography>
        </ResponsiveRow>
        <ResponsiveRow
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            padding: 0,
            justifyContent: { xs: "center", lg: "flex-end" },
          }}
          container
        >
          <ResponsiveRow
            item
            sx={{
              width: "100%",
              justifyContent: { xs: "center", lg: "flex-end" },
              flexWrap: { xs: "wrap", lg: "nowrap" },
            }}
          >
            <DatePickerInput
              labelText="Search Tax Vouchers by Date"
              onSelectDate={handleTaxFilterByDate}
            />

            <TopBarContainedBrandButton
              onClick={handleOpenTaxPaymentModal}
              sx={{ width: { xs: "100%", lg: "300px" }, height: "60px" }}
            >
              Add New Voucher
            </TopBarContainedBrandButton>
          </ResponsiveRow>
        </ResponsiveRow>
      </TopBarContainer>
      <MuiDataGrid
        loading={isLoading}
        dataGridColumns={taxesDataGridColumns}
        dataGridRows={taxRows}
        height="60vh"
      />

      <br />

      <PendingTaxPaymentModal
        handleCloseModal={() => setModalOpen(false)}
        modalOpen={modalOpen}
        handlePayment={handleProcessTaxPayment}
      />
    </MainContainer>
  );
}
