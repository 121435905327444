/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react"
import {GridActionsCellItem} from "@mui/x-data-grid"
import {
    Button,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material"
import {
    Add,
    Cancel,
    Check,
    Clear,
    Commit,
    Contacts,
    CreditCard,
    Delete,
    Done,
    Edit,
    LocalAtm,
    NearMe,
    Pause,
    PlayArrow,
    Visibility,
} from "@mui/icons-material"
import {personOrgSearch} from "../services/shared.service"
import {
    getChargeAccounts,
    getCreditAccounts,
    getFacilityOptions,
    getIdTypes,
    getRentDays,
    getRentPeriods,
    getUnitOptions,
    getVATAccount,
} from "../services/list.service"
import {
    adjustForTimezone,
    dateDifference,
    formatCurrency,
    getDayStart,
    getLeaseRefNumber,
    getVATAmount,
    isArray,
    isEmptyArray,
    isEmptyString,
    isNullUndefined,
    isValidDate,
    sortAlphaNum,
    tryParseFloat,
    tryParseInt,
} from "../util/util"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-gb"
import {useSelector} from "react-redux"
import Permit from "../util/permit"
import ReceivePayment from "../components/modals/receivePayment"
import ContractTermination from "../components/contractTermination"
import {
    activateLease,
    addLeaseContact,
    addOrDeleteUnit,
    deleteDraftLease,
    downloadLeases,
    exportLeases,
    getEditUnitDetails,
    getLeaseChargeCodes,
    getLeaseContacts,
    getLeaseDetails,
    getLeases,
    getLeaseTerms,
    getLeaseTypes,
    getTotalLeases,
    getUnitMeters,
    leaseTermination,
    leaseUnitCharges,
    removeLeaseContact,
    resendLastInvoice,
    resendLastReceipt,
    resendStatement,
    saveLeaseData,
    saveLeaseNote,
    saveLeasePayment,
    suspendReactivateLease,
    updateActivateLease,
} from "../services/lease.service"
import {TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants"
import PersonOrgSearch from "../components/modals/personOrgSearch"
import AddNewUser from "../components/modals/addNewUser"
import AddNewOrganisation from "../components/modals/addNewOrganisation"
import ConfirmationModal from "../components/modals/confirmation"
import {saveReadingBulk} from "../services/meters.service"
import LeaseView from "./leaseView"
import ExportDocumentsModal from "../components/modals/exportDocuments"
import CreditNote from "../components/modals/creditNote"
import DebitNote from "../components/modals/debitNote"
import {amber, deepOrange, green, red} from "@mui/material/colors"
import {renderCellExpand} from "../util/renderCellExpand"
import AddNoteModal from "../components/modals/rentals/leases/addNoteModal"
import SuspendLeaseModal from "../components/modals/rentals/leases/suspendLeaseModal"
import LeaseUnitSwapModal from "../components/modals/rentals/leases/leaseUnitSwapModal"
import AddLeaseModal from "../components/modals/rentals/leases/addLeaseModal"
import {ResponsiveRow} from "../constants/layout.constants"
import IncrementRentModal from "../components/modals/rentals/leases/incrementRentModal"
import SplitButton from "../components/splitButton"
import {issueInfoMessage, issueResponseMessage, issueWarnMessage,} from "../actions/message"
import ContractContactModal from "../components/modals/contractContactModal"
import {saveNewContactPerson} from "../services/users.service"
import dayjs from "dayjs"
import MuiDataGrid from "../components/modules/MuiDataGrid"

export default function Leases() {
    const [leases, setLeases] = useState([])
    const [searchReference, setSearchReference] = useState("")
    const [searchTenant, setSearchTenant] = useState("")
    const [loading, setLoading] = useState(true)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    })
    const [totalLeases, setTotalLeases] = useState(0)
    const [selectedLease, setSelectedLease] = useState(null)
    const [selectedUnitIds, setSelectedUnitIds] = useState([])
    const [selectedUnitId, setSelectedUnitId] = useState("")
    const [includeLeaseDetail, setIncludeLeaseDetail] = useState(false)
    const [terminateItem, setTerminateItem] = useState(null)
    const [suspendModal, setSuspendModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showIncrementRentModal, setShowIncrementRentModal] = useState(false)
    const [statusChangeReason, setStatusChangeReason] = useState("")
    const [addOrganizationModal, setAddOrganizationModal] = useState(false)
    const [addUserModal, setAddUserModal] = useState(false)
    const [paymentModal, setPaymentModal] = useState(false)
    const [creditModal, setCreditModal] = useState(false)
    const [creditAccounts, setCreditAccounts] = useState([])
    const [chargeAccounts, setChargeAccounts] = useState([])
    const [vatAccount, setVATAccount] = useState(null)
    const [statementYear, setStatementYear] = useState(new Date())
    const [chargeItemsTotal, setChargeItemsTotal] = useState(0)
    const [items, setItems] = useState([])
    const [chargeItems, setChargeItems] = useState([])
    const [contactTableRows, setContactTableRows] = useState()
    const [leaseChargesTableRows, setLeaseChargesTableRows] = useState("")
    const [swapUnitsTableRows, setSwapUnitsTableRows] = useState("")
    const [chargeModal, setChargeModal] = useState(false)
    const [bulkChargeAction, setBulkChargeAction] = useState(false)
    const [noteModal, setNoteModal] = useState(false)
    const [downloadModal, setDownloadModal] = useState(false)
    const [terminateModal, setTerminateModal] = useState(false)
    const [incrementType, setIncrementType] = useState("")
    const [note, setNote] = useState("")
    const [task, setTask] = useState("")
    const [innerTask, setInnerTask] = useState("")
    const [billingPeriods, setBillingPeriods] = useState([])
    const [rentDays, setRentDays] = useState([])
    const [leaseChargeCodes, setLeaseChargeCodes] = useState([])
    const [idTypes, setIdTypes] = useState([])
    const [facilityOptions, setFacilityOptions] = useState([])
    const [leaseTermOptions, setLeaseTermOptions] = useState([])
    const [leaseTypeOptions, setLeaseTypeOptions] = useState([])
    const [unitOptions, setUnitOptions] = useState([])
    const [chargeUnitsOptions, setChargeUnitsOptions] = useState([])
    const [tenantModal, setTenantModal] = useState(false)
    const [contactModal, setContactModal] = useState(false)
    const [showAddNewContact, setShowAddNewContact] = useState(false)
    const [isTenantSelect, setIsTenantSelect] = useState(false)
    const [isTenantAdd, setIsTenantAdd] = useState(false)
    const [isContactSelect, setIsContactSelect] = useState(false)
    const [showSelectUnit, setShowSelectUnit] = useState(false)
    const [idTypeSearch, setIdType] = useState("")
    const [idNoSearch, setIdNo] = useState("")
    const [companySearch, setCompanySearch] = useState("")
    const [searchBy, setSearchBy] = useState("")
    const [foundTenant, setFoundTenant] = useState([])
    const user = useSelector((state) => state.auth.user)
    const [rowSelectionModel, setRowSelectionModel] = useState([])
    const [selectedIds, setSelectedIds] = useState([])
    const [leaseContacts, setLeaseContacts] = useState([])
    const [titleText, setTitleText] = useState("")
    const [severityText, setSeverityText] = useState("")
    const [bodyText, setBodyText] = useState("")
    const [positiveText, setPositiveText] = useState("")
    const [negativeText, setNegativeText] = useState("")
    const [noOfUnitsBeforeUpdate, setNoOfUnitsBeforeUpdate] = useState(0)
    const [meterReadings, setMeterReadings] = useState(0)
    const [units, setUnits] = useState([])
    const [addedUnitsMeterReadings, setAddedUnitsMeterReadings] = useState([])
    const [prevUnits, setPrevUnits] = useState([])
    const [deletedUnitMeterReadings, setDeletedUnitMeterReadings] = useState([])
    const [voiditem, setVoiditem] = useState(null)
    const MAX_INCREMENT_PERIOD = 10

    const handlePaymentClose = () => setPaymentModal(false)

    const handleCreditClose = () => {
        setVoiditem(null)
        setCreditModal(false)
        //reload page after issuing a credit
        getLeases(
            searchReference,
            searchTenant,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((result) => {
                setLeases(result)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChargeClose = () => {
        setVoiditem(null)
        setChargeModal(false)
        setBulkChargeAction(false)
        //reload page after issuing a charge
        getLeases(
            searchReference,
            searchTenant,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((result) => {
                setLeases(result)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlePersonOrgClose = () => {
        setIsTenantSelect(false)
        setTenantModal(false)
    }
    const handleUnitSwapClose = () => {
        setTask("")
        setNoOfUnitsBeforeUpdate(0)
        setMeterReadings(0)
        setUnits([])
        setSelectedUnitId("")
        setInnerTask("")
        setShowSelectUnit(false)
    }
    const handleLeaseChargeClose = () => {
        setInnerTask("Lease Units")
        setShowSelectUnit(false)
        setSelectedUnitId("")
        setChargeUnitsOptions([])
        setChargeItems([])
    }
    const handleContactClose = () => {
        setIsContactSelect(false)
        setSearchBy("")
        setIdType("")
        setIdNo("")
        setFoundTenant([])
        setContactModal(false)
        setShowAddNewContact(false)
        setLeaseContacts([])
    }
    const handleSuspendReactivateClose = () => {
        setTask("")
        setSuspendModal(false)
    }
    const handleContactClear = () => {
        setIdType("")
        setIdNo("")
        setFoundTenant([])
    }
    const handleNoteClose = () => {
        setNote("") //Clear note
        setNoteModal(false)
    }
    const handleDownloadClose = () => {
        setRowSelectionModel([]) //Clear selection
        setStatementYear(new Date())
        setIncludeLeaseDetail(false)
        setDownloadModal(false)
        setTitleText("")
        setTask("")
        setSeverityText("")
        setBodyText("")
    }

    const handleConfirmationClose = () => {
        setShowConfirmationModal(false)
        setTitleText("")
        setTask("")
        setSeverityText("")
        setBodyText("")
        setPositiveText("")
        setNegativeText("")
        setSelectedLease(null)
        setRowSelectionModel([])
    }

    const handleTerminateClose = () => {
        setItems([]) //Clear items
        setChargeItems([]) //Clear items
        setTerminateModal(false)
    }

    const handleIncrementRentClose = () => {
        setRowSelectionModel([])
        setShowIncrementRentModal(false)
    }

    const handleAddLeaseClose = () => {
        setSelectedLease(null)
        setChargeItemsTotal(0)
        setChargeItems([])
        setIncrementType("")
        setChargeUnitsOptions([])
        setSelectedUnitIds([])
        setTask("")
    }

    const resendButtons = [
        {
            label: "RESEND ACTIONS",
            roles: false,
            services: false,
        },
        {
            action: () => handleSendLeaseDocClick("Invoice"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Invoices"
                    : "Resend Last Invoice",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 0,
        },
        {
            action: () => handleSendLeaseDocClick("Receipt"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Receipt"
                    : "Resend Last Receipt",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 0,
        },
        {
            action: () => handleSendLeaseDocClick("Statement"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Statements"
                    : "Resend Statement",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 0,
        },
    ]

    const leaseButtons = [
        {
            label: "LEASE ACTIONS",
            roles: false,
            services: false,
        },
        {
            action: () => handleIncrementRentClick(),
            label:
                rowSelectionModel.length > 1
                    ? "Increment Rent for " + rowSelectionModel.length + " leases"
                    : "Increment Rent",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 1,
        },
        {
            action: handleDownloadClick,
            label:
                rowSelectionModel.length > 1
                    ? "Export " + rowSelectionModel.length + " Lease Statements"
                    : "Export Lease Statement",
            roles: false,
            services: false,
            disabled: rowSelectionModel.length <= 1,
        },
        {
            action: bulkCharge,
            label:
                rowSelectionModel.length > 1
                    ? "Issue Charges to " + rowSelectionModel.length + " Leases"
                    : "Issue Charge",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 1,
        },
        {
            action: exportLeaseData,
            label: "Download All Leases",
            roles: false,
            services: false,
        },
    ]

    useEffect(() => {
        setLoading(true)
        getTotalLeases(searchReference, searchTenant).then((response) => {
            setTotalLeases(response.totalLeases)
        })

        getCreditAccounts().then((creditAccounts) =>
            setCreditAccounts(creditAccounts)
        )

        getChargeAccounts().then((chargeAccounts) =>
            setChargeAccounts(chargeAccounts)
        )

        getVATAccount().then((vatAccount) => setVATAccount(vatAccount))

        getLeases(
            searchReference,
            searchTenant,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((result) => {
                setLeases(result)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [
        user.proxyFacility,
        searchReference,
        searchTenant,
        paginationModel.page,
        paginationModel.pageSize,
    ])

    useEffect(() => {
        if (!isNullUndefined(selectedLease) && !isNullUndefined(selectedLease.id)) {
            getLeaseContacts(selectedLease.id).then((leaseContacts) =>
                setLeaseContacts(leaseContacts)
            )
        }

        if (
            !isNullUndefined(selectedLease) &&
            !isNullUndefined(selectedLease.facilityId)
        ) {
            getUnitOptions(selectedLease.facilityId).then((unitOptions) => {
                if (task === "Edit") {
                    let uOpt = [...unitOptions]
                    for (let u of chargeUnitsOptions) {
                        if (uOpt.filter((o) => u.value === o.value).length < 1) {
                            uOpt.push(u)
                        }
                    }
                    setUnitOptions(uOpt)
                } else {
                    setUnitOptions(unitOptions)
                }
            })
        }
    }, [selectedLease])

    useEffect(() => {
        // setLoading(true)
        getRentPeriods().then((billingPeriods) =>
            setBillingPeriods(billingPeriods)
        )

        getRentDays().then((rentDays) => setRentDays(rentDays))

        getLeaseChargeCodes().then((leaseChargeCodes) =>
            setLeaseChargeCodes(leaseChargeCodes)
        )

        getIdTypes().then((idTypes) => setIdTypes(idTypes))

        getFacilityOptions().then((facilityOptions) =>
            setFacilityOptions(facilityOptions)
        )

        getLeaseTerms().then((leaseTermOptions) =>
            setLeaseTermOptions(leaseTermOptions)
        )

        getLeaseTypes().then((leaseTypeOptions) =>
            setLeaseTypeOptions(leaseTypeOptions)
        )
        // .finally(() => {
        //   setLoading(false)
        // })
    }, [])

    useEffect(() => {
        populateLeaseContacts()
    }, [leaseContacts])

    useEffect(() => {
        populateChargeItems()
        if (!terminateModal && !chargeModal) {
            populateLeaseChargeItems()
        }
    }, [chargeItems])

    useEffect(() => {
        populateUnitSwapItems()
    }, [units])

    function searchPersonOrg() {
        let data = {
            searchType: searchBy,
            idType: idTypeSearch,
            idNumber: idNoSearch,
            organizationName: companySearch,
        }
        personOrgSearch(data).then((response) => {
            if (
                !isNullUndefined(response) &&
                isArray(response) &&
                !isEmptyArray(response)
            ) {
                setShowAddNewContact(false)
            } else {
                setShowAddNewContact(true)
            }
            setFoundTenant(response)
        })
    }

    function handleVoidClick(item) {
        if (item.type === "Charge" || item.type === "Invoice") {
            setCreditModal(true)
            //set the id of the transaction being voided
            setVoiditem(item)
        } else if (item.type === "Receipt" || item.type === "Credit") {
            setChargeModal(true)
            setVoiditem(item)
        }
    }

    function showAddNewUserForm(idNoSearch, idTypeSearch) {
        setIdType(idTypeSearch)
        setIdNo(idNoSearch)
        setIsTenantSelect(false)
        setTenantModal(false)
        setAddUserModal(true)
        setIsTenantAdd(true)
    }

    function showAddNewContactForm(idNoSearch, idTypeSearch) {
        setIdType(idTypeSearch)
        setIdNo(idNoSearch)
        setIsTenantSelect(false)
        setTenantModal(false)
        setAddUserModal(true)
        setIsContactSelect(true)
    }

    function showAddNewOrgForm(companySearch) {
        setCompanySearch(companySearch)
        setAddOrganizationModal(true)
        setIsTenantAdd(true)
    }

    function handleAddUserClose() {
        setAddUserModal(false)
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false)
    }

    function handlePersonOrgSelect(result) {
        if (isTenantSelect || isTenantAdd) {
            let obj = selectedLease
            obj.tenant = result
            setSelectedLease(obj)
            setTenantModal(false)
            setIsTenantSelect(false)
            setIsTenantAdd(false)
            setIsContactSelect(false)
            setSearchBy("")
            setIdType("")
            setIdNo("")
            setCompanySearch("")
            setFoundTenant([])
        }

        if (isContactSelect) {
            setTenantModal(false)
            setIsTenantSelect(false)
            setCompanySearch("")
            if (showAddNewContact) {
                saveNewContactPerson(result).then((response) => {
                    let data = {
                        leaseId: selectedLease.id,
                        personId: response.person.id,
                    }
                    if (validateAddLeaseContact(data)) {
                        addLeaseContact(data).then((response) => {
                            issueResponseMessage(response)
                            setShowAddNewContact(false)
                            getLeaseContacts(selectedLease.id).then((leaseContacts) =>
                                setLeaseContacts(leaseContacts)
                            )
                            handleContactClear()
                        })
                    }
                })
            } else {
                let data = {
                    leaseId: selectedLease.id,
                    personId: result.id,
                }
                if (validateAddLeaseContact(data)) {
                    addLeaseContact(data).then((response) => {
                        issueResponseMessage(response)
                        getLeaseContacts(selectedLease.id).then((leaseContacts) =>
                            setLeaseContacts(leaseContacts)
                        )
                        handleContactClear()
                    })
                }
            }
        }
    }

    function handleIdTypeChange(event) {
        let value = event.target.value
        setIdType(value)
    }

    function onRadioChange(radio) {
        const value = radio.target.value
        if (radio) {
            if (value === "Percentage") {
                setIncrementType(value)
            }
            if (value === "Amount") {
                setIncrementType(value)
            }
        }
    }

    function onTextChange(event) {
        const id = event.target.id
        const value = event.target.value
        if (id === "searchRef") {
            setSearchReference(value)
        }
        if (id === "searchTenant") {
            setSearchTenant(value)
        }
        if (id === "note") {
            setNote(value)
        }
        if (id === "idNoSearch") {
            setIdNo(value)
        }
        if (id === "companySearch") {
            setCompanySearch(value)
        }
        if (id === "statusChangeReason") {
            setStatusChangeReason(value)
        }
    }

    function creditAmountChange(event, index) {
        const value = event.target.value
        let newArr = [...items] // copying the old items array
        newArr[index].amount = value
        setItems(newArr)
        populateItems()
    }

    function deleteLease() {
        if (
            isNullUndefined(selectedLease) ||
            isNullUndefined(selectedLease.id) ||
            isEmptyString(selectedLease.id)
        ) {
            issueWarnMessage(
                "Could not determine Lease ID for deletion. Try refresh the page or contact support."
            )
            return
        }

        const sendData = {leaseId: selectedLease.id}

        deleteDraftLease(sendData).then((response) => {
            issueResponseMessage(response)
            getLeases(
                searchReference,
                searchTenant,
                paginationModel.page,
                paginationModel.pageSize
            ).then((result) => setLeases(result))
            getTotalLeases(searchReference, searchTenant).then((response) =>
                setTotalLeases(response.totalLeases)
            )
            handleConfirmationClose()
        })
    }

    function resendLastInvoiceClicked() {
        let sendData = {bulkEditIds: selectedIds}
        resendLastInvoice(sendData).then((response) => {
            issueResponseMessage(response)
            handleConfirmationClose()
        })
    }

    function resendLastReceiptClicked() {
        let sendData = {bulkEditIds: rowSelectionModel}
        resendLastReceipt(sendData).then((response) => {
            issueResponseMessage(response)
            handleConfirmationClose()
        })
    }

    function resendStatementClicked() {
        let sendData = {bulkEditIds: rowSelectionModel}
        resendStatement(sendData).then((response) => {
            issueResponseMessage(response)
            handleConfirmationClose()
        })
    }

    function activateDraftLease() {
        if (
            isNullUndefined(selectedLease) ||
            isNullUndefined(selectedLease.id) ||
            isEmptyString(selectedLease.id)
        ) {
            issueWarnMessage(
                "Could not determine Lease for activation. Try refresh the page or contact support."
            )
            return
        }

        activateLease(selectedLease).then((response) => {
            issueResponseMessage(response)
            getLeases(
                searchReference,
                searchTenant,
                paginationModel.page,
                paginationModel.pageSize
            ).then((result) => setLeases(result))
            getTotalLeases(searchReference, searchTenant).then((response) =>
                setTotalLeases(response.totalLeases)
            )
            handleConfirmationClose()
        })
    }

    function positiveAction() {
        if (task === "Delete") {
            deleteLease()
        }
        if (task === "Activate") {
            activateDraftLease()
        }
        if (task === "Resend Invoice") {
            resendLastInvoiceClicked()
        } else if (task === "Resend Receipt") {
            resendLastReceiptClicked()
        } else if (task === "Resend Statement") {
            resendStatementClicked()
        }
    }

    function suspendReActivateLeaseForm() {
        let data = {
            leaseId: selectedLease.id,
            statusChangeReason: statusChangeReason, //CASH/CHEQUE - only needed if final balance < 0
            currentStatus: selectedLease.status,
        }
        if (isEmptyString(data.statusChangeReason)) {
            issueWarnMessage("Cannot submit an without a reason for suspension")
            return false
        }
        suspendReactivateLease(data).then((response) => {
            issueResponseMessage(response)
            if (!isNullUndefined(response.lease)) {
                getLeases(
                    searchReference,
                    searchTenant,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((result) => setLeases(result))
                handleSuspendReactivateClose()
            }
        })
    }

    function handleChargeStartDateChange(value, index) {
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].effectiveDate = value
        setChargeItems(newArr)
        populateLeaseChargeItems()
    }

    function handleChargeTaxableToggle(event, index) {
        let value = event.target.checked
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].taxable = value
        setChargeItems(newArr)
        populateLeaseChargeItems()
    }

    function handleLeaseChargeItemRemove(index) {
        let newArr = [...chargeItems] // copying the old items array
        newArr.splice(index, 1)
        setChargeItems(newArr)
        populateLeaseChargeItems()
    }

    function handleUnitDelete(index) {
        let unitToDelete = units[index]
        let unitsCount = [
            ...new Map(
                selectedLease.leaseChargeItems.map((v) => [v.unit.id, v])
            ).values(),
        ].length
        let deleteUnit = true
        if (unitsCount > 1) {
            if (
                !isNullUndefined(unitToDelete.meters) &&
                isArray(unitToDelete.meters) &&
                unitToDelete.meters.length > 0
            ) {
                unitToDelete.meters.forEach((meter) => {
                    if (meter.newMeterReading != null) {
                        if (meter.newMeterReading <= meter.lastMeterReading) {
                            issueWarnMessage(
                                `Last Meter reading for meter ${meter.name} cannot be less than or equal to the previous reading.`
                            )
                            deleteUnit = false
                        }
                    }
                })
            }
            if (deleteUnit) {
                dontIssueCharge(index)
            }
        } else {
            issueWarnMessage(
                `You can't remove all Lease Units. Please add another unit first before deleting this to remain with at least one unit under lease units section.`
            )
        }
    }

    function dontIssueCharge(deleteUnitIndex) {
        let unitToDelete = units[deleteUnitIndex]
        let meterReadings = deletedUnitMeterReadings
        if (isArray(unitToDelete.meters) && unitToDelete.meters.length > 0) {
            unitToDelete.meters.forEach((meter) => {
                meterReadings.push({
                    meterId: meter.id,
                    amount: meter.newMeterReading,
                })
            })
        }
        let tmpUnits = units
        tmpUnits.splice(deleteUnitIndex, 1)
        let tmpLease = selectedLease
        tmpLease.leaseChargeItems = tmpLease.leaseChargeItems.filter(
            (charge) => charge.unit.id !== unitToDelete.id
        )
        let noOfUnits = noOfUnitsBeforeUpdate - 1
        let thePrevUnits = prevUnits
        if (!unitToDelete.dateCreated) {
            let myIndex = thePrevUnits.findIndex((e) => e.value === unitToDelete.id)
            thePrevUnits.splice(myIndex, 1)
        }
        setUnits(tmpUnits)
        setSelectedLease(tmpLease)
        setNoOfUnitsBeforeUpdate(noOfUnits)
        setPrevUnits(thePrevUnits)
        setDeletedUnitMeterReadings(meterReadings)
        populateUnitSwapItems()
    }

    function handleChargeCodeChange(event, index) {
        let value = event.target.value
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].chargeCode.code = value
        setChargeItems(newArr)
        populateLeaseChargeItems()
    }

    function handleChargeUnitChange(event, index) {
        let value = event.target.value
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].unit.id = value
        setChargeItems(newArr)
        populateLeaseChargeItems()
    }

    function leaseChargeAmountChange(event, index) {
        let value = event.target.value
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].amount = value
        setChargeItems(newArr)
        populateLeaseChargeItems()
    }

    function editLastMeterReading(event, unitIndex, meterIndex) {
        let tmp = units
        tmp[unitIndex].meters[meterIndex].newMeterReading = parseFloat(
            event.target.value
        )
        setUnits(tmp)
        populateUnitSwapItems()
    }

    function leaseChargeNoteChange(event, index) {
        let value = event.target.value
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].note = value
        setChargeItems(newArr)
        populateLeaseChargeItems()
    }

    function handleLeaseChargeItemAdd() {
        let charge = {
            unit: {
                id: "",
                name: "",
            },
            amount: "",
            chargeCode: {code: "", name: ""},
            taxable: false,
            effectiveDate: new Date(),
            note: "",
        }
        setChargeItems((chargeItems) => [...chargeItems, charge])
    }

    function chargeAmountChange(event, index) {
        const value = event.target.value
        let newArr = [...items] // copying the old items array
        newArr[index].amount = value
        setItems(newArr)
        populateChargeItems()
    }

    function findAndRemoveChargeVAT(detailItem) {
        let vatAmount = getVATAmount(detailItem.amount)
        for (let i = 0; i < items.length; i++) {
            if (
                items[i].amount === vatAmount &&
                items[i].notes.includes(detailItem.notes)
            ) {
                handleChargeItemRemove(i)
            }
        }
        populateChargeItems()
    }

    function handleChargeCheckboxToggle(event, index) {
        let newArr = [...items] // copying the old items array
        let detailItem = newArr[index]
        if (isNullUndefined(detailItem.amount) || detailItem.amount === "0") {
            issueWarnMessage("You must enter an amount before applying VAT")
        } else {
            if (event.target.checked) {
                let vatNotes = "VAT (16%) - " + detailItem.notes
                let vatAmount = getVATAmount(detailItem.amount)
                detailItem.applyVAT = true
                setItems(newArr)
                setItems((items) => [
                    ...items,
                    {
                        accountId: vatAccount.id,
                        notes: vatNotes,
                        amount: vatAmount,
                        applyVAT: false,
                        displayVATRadio: false,
                    },
                ])
                setChargeItems((chargeItems) => [
                    ...chargeItems,
                    {
                        accountId: vatAccount.id,
                        notes: vatNotes,
                        amount: vatAmount,
                        applyVAT: false,
                        displayVATRadio: false,
                    },
                ])
            } else {
                detailItem.applyVAT = false
                setItems(newArr)
                setChargeItems(newArr)
                findAndRemoveChargeVAT(detailItem)
            }
        }
    }

    function handleIncludeLeaseDetailToggle(event) {
        setIncludeLeaseDetail(event.target.checked)
    }

    function chargeItemMemoChange(event, index) {
        const value = event.target.value
        let newArr = [...items] // copying the old items array
        newArr[index].notes = value
        setItems(newArr)
        populateChargeItems()
    }

    function handleCreditItemRemove(index) {
        let newArr = [...items] // copying the old items array
        if (newArr.length > 1) {
            newArr.splice(index, 1)
            setItems(newArr)
        } else {
            issueWarnMessage("There must be at least one credit item")
        }
    }

    function handleChargeItemRemove(index) {
        let newArr = [...items] // copying the old items array
        if (newArr.length > 1) {
            findAndRemoveChargeVAT(items[index])
            newArr.splice(index, 1)
            setItems(newArr)
            setChargeItems(items)
        } else {
            issueWarnMessage("There must be at least one charge")
        }
    }

    function handleLeaseContactRemove(personId) {
        if (
            isNullUndefined(selectedLease) ||
            isNullUndefined(selectedLease.id) ||
            isEmptyString(selectedLease.id)
        ) {
            issueWarnMessage(
                "Something went wrong: couldn't find the title to retrieve the contacts. Try refresh the page."
            )
            return
        }
        if (isNullUndefined(personId) || isEmptyString(personId)) {
            issueWarnMessage(
                "Couldn't find the person identifier. Try refresh the page"
            )
            return
        }
        let data = {
            leaseId: selectedLease.id,
            personId: personId,
        }
        removeLeaseContact(data).then((response) => {
            issueResponseMessage(response)
            getLeaseContacts(selectedLease.id).then((leaseContacts) =>
                setLeaseContacts(leaseContacts)
            )
            handleContactClear()
        })
    }

    function handlePaymentClick(selectedLease) {
        setSelectedLease(selectedLease)
    setPaymentModal(true)
    }

    function handleTerminateClick(selectedLease) {
        let data = {
            leaseId: selectedLease.id,
            paymentMethod: "", //CASH/CHEQUE - only needed if final balance < 0
            cashAccountId: "", //When Cash, must select which account
            chequeNumber: "", // When Cheque
            bankRefNumber: "", // For RTGS payments
            charges: [],
        }
        setTerminateItem(data)
        setItems([])
        setChargeItems([])
        setSelectedLease(selectedLease)
        setTerminateModal(true)
    }

    function handleNoteClick(selectedLease) {
        setSelectedLease(selectedLease)
        setNoteModal(true)
    }

    function handleCreditClick(selectedLease) {
        setSelectedLease(selectedLease)
        setCreditModal(true)
    }

    function handleContactClick(selectedLease) {
        setSelectedLease(selectedLease)
        setContactModal(true)
        setIsContactSelect(true)
        setSearchBy("Individual")
    }

    function handleChargeClick(selectedLease) {
        setSelectedLease(selectedLease)
        setChargeModal(true)
    }

    function handleUnitChange(event) {
        let value = event.target.value
        let obj = selectedLease
        obj.unitIds = value
        setSelectedUnitIds(value)
        setSelectedLease(obj)
        let currentItems = [...chargeItems]
        let options = []
        for (let id of value) {
            for (let unit of unitOptions) {
                if (unit.value === id) {
                    options.push(unit)
                }
            }
        }
        setChargeUnitsOptions([
            ...new Map(options.map((v) => [v.value, v])).values(),
        ])
        let charges = []
        for (let opt of options) {
            for (let itm of currentItems) {
                if (opt.value === itm.unit.id) {
                    charges.push(itm)
                }
            }
        }
        currentItems = charges
        leaseUnitCharges(value).then((response) => {
            for (let item of response) {
                let addItem = true
                for (let charge of currentItems) {
                    if (charge.unit.id === item.id) {
                        addItem = false
                    }
                }
                if (addItem) {
                    currentItems.push({
                        unit: {id: item.id, name: ""},
                        amount: item.rent,
                        chargeCode: {code: item.code, name: ""},
                        taxable: false,
                        effectiveDate:
                            (
                            isEmptyString(selectedLease && selectedLease.effectiveDate) ||
                            isNullUndefined(selectedLease && selectedLease.effectiveDate)
                            ) ? new Date() : selectedLease && selectedLease.effectiveDate,
                        note: "",
                    })
                }
            }
            setChargeItems(currentItems)
        })
        populateLeaseChargeItems()
    }

    function handleSwapUnitChange(event) {
        let value = event.target.value
        setSelectedUnitId(value)
        let obj = selectedLease
        obj.unitIds.push(value)
        let unitIds = []
        unitIds.push(value)
        setSelectedLease(obj)
        let currentItems = []
        let options = []
        for (let unit of unitOptions) {
            if (unit.value === value) {
                options.push(unit)
            }
        }
        setChargeUnitsOptions([
            ...new Map(options.map((v) => [v.value, v])).values(),
        ])
        leaseUnitCharges(unitIds).then((response) => {
            for (let item of response) {
                currentItems.push({
                    unit: {id: item.id, name: ""},
                    amount: item.rent,
                    chargeCode: {code: item.code, name: ""},
                    taxable: false,
                    effectiveDate: new Date(),
                    note: "",
                })
            }
            setInnerTask("Lease Charges")
            setChargeItems(currentItems)
        })
        populateLeaseChargeItems()
    }

    function leaseTypeChanged(event) {
        let value = event.target.value
        let obj = selectedLease
        obj.leaseTypeCd = value
        setSelectedLease(obj)
    }

    function handleStatementYearChange(value) {
        setStatementYear(value)
    }

    function handleViewClick(selectedLease) {
        getLeaseDetails(selectedLease.id).then((response) => {
            if (!isNullUndefined(response.id)) {
                setSelectedLease(response)
                setTask("View")
            }
        })
    }

    function handleAddLeaseClick() {
        let facilityId = null
        if (user.proxyFacility != null) {
            facilityId = user.proxyFacility
        }
        let refNumber = getLeaseRefNumber()
        let data = {
            id: null,
            effectiveDate: "",
            expirationDate: null,
            tenant: {
                id: null,
                name: null,
            },
            facilityId: facilityId,
            unitIds: [],
            statusCd: "DRF",
            billingCode: refNumber,
            leaseTypeCd: null,
            leaseChargeItems: [],
            incrementPeriod: 0, // default to increment after 1 year
        }
        setSelectedLease(data)
        setTask("Create")
    }

    function handleSuspendClick(selectedLease) {
        setSelectedLease(selectedLease)
        setTask("Suspend")
        setSuspendModal(true)
    }

    function handleReActivateClick(selectedLease) {
        setSelectedLease(selectedLease)
        setTask("Re-Activate")
        setSuspendModal(true)
    }

    function handleDeleteDraftLeaseClick(selectedLease) {
        setSelectedLease(selectedLease)
        let title =
            "Confirm Delete Lease " +
            selectedLease.billingCode +
            " for " +
            selectedLease.tenant
        let body =
            "Are you sure you want to permanently delete this lease along with any other associated data such as charges, contacts, and documents?"
        setTitleText(title)
        setTask("Delete")
        setSeverityText("warning")
        setBodyText(body)
        setPositiveText("Delete Lease")
        setNegativeText("Cancel")
        setShowConfirmationModal(true)
    }

    function handleActivateDraftLeaseClick(selectedLease) {
        selectedLease.activateAndInvoice = false
        setSelectedLease(selectedLease)
        let title =
            "Activate Lease " +
            selectedLease.billingCode +
            " for " +
            selectedLease.tenant
        let body = "Are you sure you wish to Activate this lease?"
        setTitleText(title)
        setTask("Activate")
        setSeverityText("info")
        setBodyText(body)
        setPositiveText("Yes")
        setNegativeText("No")
        setShowConfirmationModal(true)
    }

    function handleEditClick(leaseToEdit) {
        getLeaseDetails(leaseToEdit.id).then((response) => {
            if (!isNullUndefined(response.id)) {
                setSelectedUnitIds(response.unitIds)
                if (
                    !isNullUndefined(response.incrementPeriod) &&
                    response.incrementPeriod > 0
                ) {
                    setIncrementType(response.incrementType)
                }
                if (
                    isArray(response.leaseChargeItems) &&
                    response.leaseChargeItems.length > 0
                ) {
                    let opt = []
                    for (let item of response.leaseChargeItems) {
                        if (opt.filter((o) => o.value === item.unit.id).length < 1) {
                            opt.push({
                                label: item.unit.name,
                                value: item.unit.id,
                            })
                        }
                    }
                    setChargeUnitsOptions([
                        ...new Map(opt.map((v) => [v.value, v])).values(),
                    ])
                    setChargeItems(response.leaseChargeItems)
                }
                setTask("Edit")
                setSelectedLease(response)
            }
        })
    }

    function handleUnitSwapClick() {
        getEditUnitDetails(selectedLease.id).then((data) => {
            if (isArray(data) && !isEmptyArray(data)) {
                let noOfReadings = data.map((e) =>
                    e.meters.length === 0 ? null : e.meters.map((e) => e.readings.length)
                )
                data = data.map((item) => {
                    if (item.meters) {
                        item.meters = item.meters.map((meter) => {
                            meter.newMeterReading = null
                            return meter
                        })
                    }
                    return item
                })
                setNoOfUnitsBeforeUpdate(data.length)
                setMeterReadings(noOfReadings)
                setUnits(data)
                setTask("Unit Swap")
                setInnerTask("Lease Units")
            }
        })
    }

    function handleActiveEditClick(leaseToEdit) {
        getLeaseDetails(leaseToEdit.id).then((response) => {
            if (!isNullUndefined(response.id)) {
                setSelectedUnitIds(response.unitIds)
                setSelectedLease(response)
                if (
                    !isNullUndefined(response.incrementPeriod) &&
                    response.incrementPeriod > 0
                ) {
                    setIncrementType(response.incrementType)
                }
                if (
                    isArray(response.leaseChargeItems) &&
                    response.leaseChargeItems.length > 0
                ) {
                    let opt = []
                    for (let item of response.leaseChargeItems) {
                        opt.push({
                            label: item.unit.name,
                            value: item.unit.id,
                        })
                    }
                    setChargeUnitsOptions([
                        ...new Map(opt.map((v) => [v.value, v])).values(),
                    ])
                    setChargeItems(response.leaseChargeItems)
                }
                setTask("ActiveLeaseEdit")
            }
        })
    }

    function savePayment(data) {
        data.leaseId = selectedLease.id
        saveLeasePayment(data).then((response) => {
            issueResponseMessage(response)
            if (!isNullUndefined(response) && !isNullUndefined(response.lease)) {
                getLeases(
                    searchReference,
                    searchTenant,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((result) => setLeases(result))
                handlePaymentClose()
            }
        })
    }

    function saveEditUnit() {
        // check that for a unit that has meters and was added to the lease
        // the initial meter readings were provided for all meter.
        let readyToSendData = true
        let addedUnitsMeterReadings = []
        units.forEach((unit) => {
            if (unit.meters) {
                unit.meters.forEach((meter) => {
                    if (meter.newMeterReading != null) {
                        if (meter.newMeterReading <= meter.lastMeterReading) {
                            issueWarnMessage(
                                `Initial Meter reading for meter ${meter.name} cannot be less than or equal to the previous reading.`
                            )
                            readyToSendData = false
                        } else {
                            addedUnitsMeterReadings.push({
                                meterId: meter.id,
                                newReading: meter.newMeterReading,
                            })
                        }
                    }
                })
            }
        })
        if (readyToSendData) {
            setAddedUnitsMeterReadings(addedUnitsMeterReadings)
            saveDeletedUnitsMeterReadings()
        }
    }

    function saveDeletedUnitsMeterReadings() {
        let params = deletedUnitMeterReadings
        let data = {readingDetails: params}
        if (Object.keys(params).length !== 0) {
            saveReadingBulk(data).then((response) => {
                issueResponseMessage(response)
                saveSwap()
            })
        } else {
            saveSwap()
        }
    }

    function saveSwap() {
        let sendData = selectedLease
        sendData.meterReadings = addedUnitsMeterReadings
        addOrDeleteUnit(sendData).then((response) => {
            issueResponseMessage(response)
            getLeases(
                searchReference,
                searchTenant,
                paginationModel.page,
                paginationModel.pageSize
            ).then((result) => setLeases(result))
            handleUnitSwapClose()
        })
    }

    function saveLeaseCharges() {
        if (innerTask === "Lease Charges") {
            let tmp = selectedLease
            tmp.leaseChargeItems = tmp.leaseChargeItems.concat(chargeItems)
            tmp.unitIds.push(selectedUnitId)
            let prevUnitsTemp = prevUnits
            let unitSelectedIndex = unitOptions.findIndex(
                (e) => e.value === selectedUnitId
            )
            prevUnitsTemp.push(unitOptions[unitSelectedIndex])
            let noOfUnits = noOfUnitsBeforeUpdate + 1
            setSelectedLease(tmp)
            setPrevUnits(prevUnitsTemp)
            setNoOfUnitsBeforeUpdate(noOfUnits)
            let periodicCharge = 0
            let balance = 0
            chargeItems.forEach((current) => {
                let amount = parseFloat(current.amount)
                let code = current.chargeCode.code
                if (
                    code === "SECDP" ||
                    code === "LFEE" ||
                    code === "MISC" ||
                    code === "UTIDP"
                ) {
                    balance += amount
                } else periodicCharge += amount
            })
            getUnitMeters(selectedUnitId).then((data) => {
                let unit = {
                    balance: balance,
                    id: unitOptions[unitSelectedIndex].value,
                    name: unitOptions[unitSelectedIndex].label,
                    periodicCharge: periodicCharge,
                    tenant: units[0].tenant,
                    meters: data,
                }
                let noOfReadingsPerMeter = []
                if (data.length > 0) {
                    noOfReadingsPerMeter = data.map((item) => item.readings.length)
                }
                let tmpReadings = meterReadings
                tmpReadings.push(noOfReadingsPerMeter)
                let tmp = units
                tmp.push(unit)
                setMeterReadings(tmpReadings)
                setUnits(tmp)
                issueInfoMessage("Unit added and you can now proceed.")
                populateUnitSwapItems()
                handleLeaseChargeClose()
            })
        }
    }

    function saveLease() {
        let obj = selectedLease
        obj.leaseChargeItems = chargeItems
        if(!isNullUndefined(obj.start) && isNullUndefined(obj.effectiveDate)){
            obj.effectiveDate = new Date(obj.start)
        } else if(!isNullUndefined(obj.effectiveDate)){
            obj.effectiveDate = new Date(obj.effectiveDate)
        }

        if(!isNullUndefined(obj.end) && isNullUndefined(obj.expirationDate)){
            obj.expirationDate = new Date(obj.end)
        } else if(!isNullUndefined(obj.expirationDate)){
            obj.expirationDate = new Date(obj.expirationDate)
        }
        setSelectedLease(obj)

        if (selectedLease.leaseChargeItems.length < 1) {
            issueWarnMessage(
                "You must add at least one lease charge to save a lease."
            )
            return
        }

        let incrementPeriod = tryParseInt(selectedLease.incrementPeriod, -1)
        if (incrementPeriod > MAX_INCREMENT_PERIOD) {
            issueWarnMessage(
                "Increment period is currently limited to a maximum of " +
                MAX_INCREMENT_PERIOD +
                " years. Reduce the increment period value"
            )
            return
        }

        let sendData = selectedLease
        sendData.incrementType = incrementType // increment type is set as first level key in state, so need to override params of the selected lease object to send
        if (validateLease(sendData)) {
            saveLeaseData(selectedLease).then((response) => {
                getLeases(
                    searchReference,
                    searchTenant,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((result) => setLeases(result))
                getTotalLeases(searchReference, searchTenant).then((response) =>
                    setTotalLeases(response.totalLeases)
                )
                issueResponseMessage(response)
                handleAddLeaseClose()
            })
        }
    }

    function updateLease() {
        let obj = selectedLease
        obj.leaseChargeItems = chargeItems
        setSelectedLease(obj)

        if (selectedLease.leaseChargeItems.length < 1) {
            issueWarnMessage(
                "You must add at least one lease charge to save a lease."
            )
            return
        }

        let incrementPeriod = tryParseInt(selectedLease.incrementPeriod, -1)
        if (incrementPeriod > MAX_INCREMENT_PERIOD) {
            issueWarnMessage(
                "Increment period is currently limited to a maximum of " +
                MAX_INCREMENT_PERIOD +
                " years. Reduce the increment period value"
            )
            return
        }

        let sendData = selectedLease
        sendData.incrementType = incrementType // increment type is set as first level key in state, so need to override params of the selected lease object to send

        if (validateLease(sendData)) {
            updateActivateLease(selectedLease).then((response) => {
                getLeases(
                    searchReference,
                    searchTenant,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((result) => setLeases(result))
                issueResponseMessage(response)
                handleAddLeaseClose()
            })
        }
    }

    function handleIncrementRentClick() {
        setShowIncrementRentModal(true)
    }

    function handleSendLeaseDocClick(docType) {
        let title = "Confirm Send " + docType
        let body =
            "Are you sure you want to resend the last " +
            docType +
            " for " +
            rowSelectionModel.length +
            " selected lease(s)?"
        setTitleText(title)
        setTask("Resend " + docType)
        setSeverityText("warning")
        setBodyText(body)
        setPositiveText("Send")
        setNegativeText("Cancel")
        setShowConfirmationModal(true)
    setRowSelectionModel(rowSelectionModel)
    setSelectedIds(rowSelectionModel)
  }

    function exportLeaseData() {
        exportLeases()
    }

    function handleDownloadClick() {
        setDownloadModal(true)
        let title =
            rowSelectionModel.length > 1
                ? "Download " + rowSelectionModel.length + " Leases"
                : "Download Lease"
        let body =
            "Note: Processing the selected Leases may take some time." +
            " Therefore, the exported data will be emailed to you when it is finished being packaged." +
            " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data."
        setTitleText(title)
        setSeverityText("info")
        setBodyText(body)
    }

    function bulkCharge() {
        setChargeModal(true)
        setBulkChargeAction(true)
    }

    function positiveExportDocsAction() {
        downloadLeaseDocs()
    }

    function downloadLeaseDocs() {
        if (isNullUndefined(rowSelectionModel) || rowSelectionModel.length < 1) {
            issueWarnMessage(
                "Could not determine the download items. Please refresh the page and try again."
            )
            return
        }
        let data = {
            leaseIds: rowSelectionModel,
            includeLeaseDetails: includeLeaseDetail,
            statementYear: dayjs(statementYear).year(), // e.g. 2022
        }

        downloadLeases(data).then((response) => {
            issueResponseMessage(response)
            handleDownloadClose()
        })
    }

    function populateItems() {
        let rows = ""
        let total = 0
        items &&
        items.length > 0 &&
        (rows = items.map(function (creditItem, i) {
            let amount = tryParseFloat(creditItem.amount, -1)
            if (amount > 0) {
                total = total + amount
            }
            return (
                <TableRow key={i}>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"creditAccount" + i}
                            name={"creditAccount" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].accountId}
                            onChange={(event) => handleCreditAccountChange(event, i)}
                        >
                            {creditAccounts && creditAccounts.length > 0 ? (
                                creditAccounts.map(function (creditAccount, i) {
                                    return (
                                        <MenuItem key={i} value={creditAccount.value}>
                                            {creditAccount.label}
                                        </MenuItem>
                                    )
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"creditAmount" + i}
                            name={"creditAmount" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].amount}
                            onChange={(event) => creditAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleCreditItemRemove(i)}>
                            <Cancel sx={{color: "red", fontSize: "2rem"}}/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            )
        }, this))
    }

    function populateUnitIds() {
        return (
            <Select
                label="Unit(s)"
                multiple
                sx={{width: {xs: "100%", lg: "90%"}}}
                id={"units"}
                isrequired={true}
                value={selectedUnitIds}
                disabled={task === "ActiveLeaseEdit"}
                onChange={(event) => handleUnitChange(event)}
            >
                {unitOptions && unitOptions.length > 0 ? (
                    unitOptions.map(function (unit, i) {
                        return (
                            <MenuItem key={i} value={unit.value}>
                                {unit.label}
                            </MenuItem>
                        )
                    }, this)
                ) : (
                    <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                )}
            </Select>
        )
    }

    function populateChargeItems() {
        let rows = ""
        items &&
        items.length > 0 &&
        (rows = items.map(function (chargeItem, i) {
            return (
                <TableRow key={i}>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"chargeAccount" + i}
                            name={"chargeAccount" + i}
                            isrequired={true}
                            disabled={items && items[i] && !items[i].displayVATRadio}
                            value={items && items[i] && items[i].accountId}
                            onChange={(event) => handleChargeAccountChange(event, i)}
                        >
                            {chargeAccounts && chargeAccounts.length > 0 ? (
                                chargeAccounts.map(function (chargeAccount, i) {
                                    return (
                                        <MenuItem key={i} value={chargeAccount.value}>
                                            {chargeAccount.label}
                                        </MenuItem>
                                    )
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeItemMemo" + i}
                            name={"chargeItemMemo" + i}
                            isrequired={true}
                            disabled={items && items[i] && !items[i].displayVATRadio}
                            value={items && items[i] && items[i].notes}
                            onChange={(event) => chargeItemMemoChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeAmount" + i}
                            name={"chargeAmount" + i}
                            isrequired={true}
                            disabled={items && items[i] && !items[i].displayVATRadio}
                            value={items && items[i] && items[i].amount}
                            onChange={(event) => chargeAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        {items && items[i] && items[i].displayVATRadio && (
                            <Checkbox
                                checked={items && items[i] && items[i].applyVAT}
                                onChange={(event) => handleChargeCheckboxToggle(event, i)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        )}
                    </TableCell>
                    <TableCell>
                        {items && items[i] && items[i].displayVATRadio && (
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{marginLeft: "10px"}}
                                onClick={() => handleChargeItemRemove(i)}
                                color="error"
                            >
                                <Cancel/>
                            </Button>
                        )}
                    </TableCell>
                </TableRow>
            )
        }, this))
    }

    function populateLeaseContacts() {
        let rows = ""
        leaseContacts &&
        leaseContacts.length > 0 &&
        (rows = leaseContacts.map(function (contact, i) {
            let phone = contact.mobilePhone
            return (
                <TableRow key={i}>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{phone}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={() => handleLeaseContactRemove(contact.personId)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }, this))
        if (rows !== "") setContactTableRows(rows)
    }

  function populateLeaseChargeItems() {
    let rows = ""
    let total = 0
    chargeItems &&
      chargeItems.length > 0 &&
      (rows = chargeItems.map(function (charge, i) {
        let amount = tryParseFloat(charge.amount, -1)
        if (amount > 0) {
          total = total + amount
        }
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"unit"}
                value={charge.unit.id}
                disabled={
                  task === "ActiveLeaseEdit" &&
                  (!isNullUndefined(charge.id) || !isEmptyString(charge.id))
                }
                onChange={(event) => handleChargeUnitChange(event, i)}
              >
                {chargeUnitsOptions && chargeUnitsOptions.length > 0 ? (
                  chargeUnitsOptions.map(function (unit, i) {
                    return (
                      <MenuItem key={i} value={unit.value}>
                        {unit.label}
                      </MenuItem>
                    )
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"chargeCode" + i}
                value={charge.chargeCode.code}
                disabled={
                  task === "ActiveLeaseEdit" &&
                  (!isNullUndefined(charge.id) || !isEmptyString(charge.id))
                }
                onChange={(event) => handleChargeCodeChange(event, i)}
              >
                {leaseChargeCodes && leaseChargeCodes.length > 0 ? (
                  leaseChargeCodes.map(function (chargeCode) {
                    let disabled = false
                    for (let chargeValue of chargeItems) {
                      if (
                        charge.unit.id === chargeValue.unit.id &&
                        chargeValue.chargeCode.code === chargeCode.value
                      ) {
                        disabled = true
                      }
                    }
                    return (
                      <MenuItem
                        key={chargeCode.value}
                        disabled={disabled}
                        value={chargeCode.value}
                      >
                        {chargeCode.label}
                      </MenuItem>
                    )
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"chargeAmount" + i}
                name={"chargeAmount" + i}
                isrequired={true}
                disabled={false}
                value={charge.amount}
                onChange={(event) => leaseChargeAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  sx={{ width: "90%" }}
                  id={"chargeStartDate"}
                  value={dayjs(charge.effectiveDate)}
                  disabled={true}
                  onChange={(newDate) =>
                    handleChargeStartDateChange(newDate, i)
                  }
                />
              </LocalizationProvider>
            </TableCell>
            <TableCell>
              {(charge.chargeCode.code === "SERCH" ||
                charge.chargeCode.code === "REN" ||
                charge.chargeCode.code === "ELCUT") && (
                <Switch
                  checked={charge.taxable}
                  onChange={(event) => handleChargeTaxableToggle(event, i)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {(charge.chargeCode.code === "LFEE" ||
                charge.chargeCode.code === "MISC") && (
                <TextField
                  sx={{ width: "90%" }}
                  id={"chargeNote" + i}
                  name={"chargeNote" + i}
                  isrequired={true}
                  disabled={false}
                  value={charge.note}
                  onChange={(event) => leaseChargeNoteChange(event, i)}
                />
              )}
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={() => handleLeaseChargeItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        )
      }, this))
    setLeaseChargesTableRows(rows)
    setChargeItemsTotal(total)
  }

    function populateUnitSwapItems() {
        let rows = ""
        let meterIndex = 0
        let selectedMeter = null
        units &&
        units.length > 0 &&
        (rows = units.map(function (unit, i) {
            let meterOptions = unit.meters
                ? unit.meters.map((meter) => {
                    return {value: meter.id, label: meter.name}
                })
                : []
            let placeholder = "No Meter attached to Unit."
            return (
                <TableRow key={i}>
                    <TableCell>{unit.name}</TableCell>
                    <TableCell>{unit.tenant}</TableCell>
                    <TableCell>{formatCurrency(unit.periodicCharge)}</TableCell>
                    <TableCell>
                        {meterOptions.length > 0 ? (
                            <Select
                                sx={{width: "90%"}}
                                id={"meter"}
                                placeholder="Select Meter"
                                value={unit.meters[meterIndex].id}
                                onChange={(value) => {
                                    let index = meterOptions.findIndex(
                                        (e) => e.value === value.value
                                    )
                                    meterIndex = index
                                    selectedMeter = unit.meters[index]
                                }}
                            >
                                {meterOptions && meterOptions.length > 0 ? (
                                    meterOptions.map(function (meter, i) {
                                        return (
                                            <MenuItem key={i} value={meter.value}>
                                                {meter.label}
                                            </MenuItem>
                                        )
                                    }, this)
                                ) : (
                                    <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                                )}
                            </Select>
                        ) : (
                            placeholder
                        )}
                    </TableCell>
                    <TableCell>
                        {meterOptions.length > 0 ? (
                            <TextField
                                sx={{width: "90%"}}
                                id={"reading" + i}
                                name={"reading" + i}
                                placeholder={
                                    unit.meters[meterIndex].lastMeterReading
                                        ? unit.meters[meterIndex].lastMeterReading
                                        : "0"
                                }
                                onChange={(event) => {
                                    editLastMeterReading(event, i, meterIndex)
                                }}
                                value={
                                    isNullUndefined(unit.meters[meterIndex].newMeterReading)
                                        ? ""
                                        : unit.meters[meterIndex].newMeterReading
                                }
                            />
                        ) : (
                            placeholder
                        )}
                    </TableCell>
                    <TableCell>
                        {/* {units.length > 1 && ( */}
                        <IconButton
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={() => handleUnitDelete(i)}
                            color="error"
                        >
                            <Delete sx={{color: "red"}}/>
                        </IconButton>
                        {/* )} */}
                    </TableCell>
                </TableRow>
            )
        }, this))
        setSwapUnitsTableRows(rows)
    }

    function handleAddSwapLeaseUnit() {
    }

    function handleCreditAccountChange(event, index) {
        let value = event.target.value
        let newArr = [...items] // copying the old items array
        newArr[index].accountId = value
        setItems(newArr)
        populateItems()
    }

    function handleChargeAccountChange(event, index) {
        let value = event.target.value
        let newArr = [...items] // copying the old items array
        newArr[index].accountId = value
        setItems(newArr)
        populateChargeItems()
    }

    function validateAddLeaseContact(data) {
        if (isNullUndefined(data.leaseId) || isEmptyString(data.leaseId)) {
            issueWarnMessage(
                "Something went wrong: couldn't find the lease to retrieve the contacts. Try refresh the page."
            )
            return false
        }
        if (isNullUndefined(data.personId) || isEmptyString(data.personId)) {
            issueWarnMessage(
                "Couldn't find the person identifier. Try refresh the page"
            )
            return false
        }
        let personId = data.personId.replace("_P", "")
        let duplicateContact = leaseContacts.find((contact) => {
            return contact.personId === personId
        })
        if (!isNullUndefined(duplicateContact)) {
            issueWarnMessage(
                "Cannot add a contact that has already been linked to this lease"
            )
            return false
        }
        return true
    }

    function validateLease(sendData) {
        // Validate
        if (isNullUndefined(sendData)) {
            issueWarnMessage("Could not determine lease")
            return false
        }
        if (isNullUndefined(sendData.billingPeriod)) {
            issueWarnMessage("Could not determine lease rent frequency")
            return false
        }
        if (
            (sendData.billingPeriod === "WEEK" ||
                sendData.billingPeriod === "MNTH") &&
            (isNullUndefined(sendData.rentDay) || isNaN(sendData.rentDay))
        ) {
            issueWarnMessage("Could not determine lease rent day")
            return false
        }
        sendData.rentDay = parseInt(sendData.rentDay)
        if (sendData.billingPeriod === "ANU") {
            let rentDate = new Date(sendData.rentDate)
            if (!isValidDate(rentDate)) {
                issueWarnMessage("Could not determine lease rent date")
                return false
            }
        }
        if (sendData.billingPeriod === "QTR" || sendData.billingPeriod === "QT") {
            let rentDate = new Date(sendData.rentDate)
            if (!isValidDate(rentDate)) {
                issueWarnMessage("Could not determine lease rent date")
                return false
            }
        }
        let incrementPeriod = tryParseInt(selectedLease.incrementPeriod, -1)
        if (incrementPeriod > MAX_INCREMENT_PERIOD) {
            issueWarnMessage(
                "Increment period is currently limited to a maximum of " +
                MAX_INCREMENT_PERIOD +
                " years. Reduce the increment period value"
            )
            return false
        }
        let rentIncrementPercentage = !isNullUndefined(
            sendData.rentIncrementPercentage
        )
            ? tryParseFloat(sendData.rentIncrementPercentage.toString(), -1)
            : -1
        let rentIncrementAmount = !isNullUndefined(sendData.rentIncrementAmount)
            ? tryParseFloat(sendData.rentIncrementAmount.toString(), -1)
            : -1
        if (incrementPeriod > 0) {
            // when 0 means rent increment not being used
            if (
                isNullUndefined(sendData.incrementType) ||
                isEmptyString(sendData.incrementType)
            ) {
                issueWarnMessage("No increment type selected")
                return false
            }
            if (
                sendData.incrementType === "Percentage" &&
                (isNullUndefined(sendData.rentIncrementPercentage) ||
                    isNaN(sendData.rentIncrementPercentage))
            ) {
                issueWarnMessage(
                    "Rent percentage increment is required when incrementing rent by a percentage"
                )
                return false
            }

            if (
                (rentIncrementPercentage < 0 || rentIncrementPercentage > 100) &&
                sendData.incrementType === "Percentage"
            ) {
                issueWarnMessage(
                    "Increment percentage can only be between 0% and 100%"
                )
                return false
            }
            if (
                sendData.incrementType === "Amount" &&
                (isNullUndefined(sendData.rentIncrementAmount) ||
                    isNaN(sendData.rentIncrementAmount))
            ) {
                issueWarnMessage(
                    "Increment rent amount is required when incrementing rent by an amount"
                )
                return false
            }
            if (rentIncrementAmount < 0 && sendData.incrementType === "Amount") {
                issueWarnMessage("Increment amount can only be between 0% and 100%")
                return false
            }
        }

        sendData.rentIncrementPercentage = tryParseInt(rentIncrementPercentage, 0)
        sendData.rentIncrementAmount = tryParseInt(rentIncrementAmount, 0)

        let leaseEffective = sendData.start ? new Date(sendData.start) : new Date(sendData.effectiveDate)

        if (!isValidDate(leaseEffective)) {
            issueWarnMessage("Cannot proceed validating the lease start date.")
            return false
        }

        if (
            isNullUndefined(sendData.leaseTerms) ||
            isEmptyString(sendData.leaseTerms) ||
            sendData.leaseTerms === null
        ) {
            issueWarnMessage("Please provide a Lease Term.")
            return false
        }

        if (sendData.leaseTerms === "FXD") {
            sendData.expirationDate = new Date(sendData.expirationDate)
            if (!isValidDate(sendData.expirationDate)) {
                issueWarnMessage("Cannot proceed validating the lease End date.")
                return false
            }
        }

        if (
            !isNullUndefined(sendData.expirationDate) &&
            isValidDate(sendData.expirationDate)
        ) {
            if (sendData.expirationDate <= getDayStart(new Date())) {
                issueWarnMessage("Lease expiration date cannot be before today")
                return false
            }
            if (sendData.expirationDate <= sendData.effectiveDate) {
                issueWarnMessage(
                    "Lease expiration date cannot be before lease start date"
                )
                return false
            }
            sendData.expirationDate = adjustForTimezone(sendData.expirationDate)
        }

        if (isArray(sendData.leaseChargeItems)) {
            for (let charge of sendData.leaseChargeItems) {
                let amount = tryParseFloat(charge.amount.toString(), -1)
                if (amount < 1) {
                    let message =
                        "Amount '" + charge.amount + "' is invalid. Cannot be below 0"
                    if (
                        !isNullUndefined(charge.chargeCode) &&
                        !isEmptyString(charge.chargeCode.name)
                    ) {
                        message =
                            "Amount for charge " +
                            charge.chargeCode.name +
                            " cannot be below 0"
                    }
                    issueWarnMessage(message)
                    return false
                }
            }
        }
        if (sendData.tenant.id === null && sendData.tenant.name === null) {
            issueWarnMessage("Lease must have at least one tenant.")
            return false
        }
        if (
            isNullUndefined(sendData.leaseTypeCd) ||
            isEmptyString(sendData.leaseTypeCd)
        ) {
            issueWarnMessage("Please provide a Lease Type.")
            return false
        }

        setSelectedLease(sendData)
        return true
    }

    function saveNote() {
        let data = {
            leaseId: selectedLease.id,
            note: note,
        }
        saveLeaseNote(data).then((response) => {
            issueResponseMessage(response)
            handleNoteClose()
        })
    }

    function terminateLease(data) {
        leaseTermination(data).then((response) => {
            issueResponseMessage(response)
            getLeases(
                searchReference,
                searchTenant,
                paginationModel.page,
                paginationModel.pageSize
            ).then((result) => setLeases(result))
            getTotalLeases(searchReference, searchTenant).then((response) =>
                setTotalLeases(response.totalLeases)
            )
            handleTerminateClose()
        })
    }

    function getChipProps(params) {
        if (!isNullUndefined(params.value)) {
            if (params.value === "DRF") {
                return {
                    icon: <Commit style={{fill: deepOrange[500]}}/>,
                    label: "Draft",
                    style: {
                        borderColor: deepOrange[500],
                    },
                }
            } else if (params.value === "ACT") {
                return {
                    icon: <Check style={{fill: green[500]}}/>,
                    label: "Active",
                    style: {
                        borderColor: green[500],
                    },
                }
            } else if (params.value === "SUP") {
                return {
                    icon: <Pause style={{fill: amber[500]}}/>,
                    label: "Suspended",
                    style: {
                        borderColor: amber[500],
                    },
                }
            } else if (params.value === "TER") {
                return {
                    icon: <Clear style={{fill: red[500]}}/>,
                    label: "Terminated",
                    style: {
                        borderColor: red[500],
                    },
                }
            } else {
                return "N/A"
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                headerName: "Billing Code",
                field: "billingCode",
                minWidth: 150,
                flex: 1.5,
            },
            {
                headerName: "Unit(s)",
                field: "unitNames",
                minWidth: 100,
                flex: 1,
                // renderCell: renderCellExpand,
                sortComparator: sortAlphaNum,
            },
            {
                headerName: "Time Left",
                field: "expirationDate",
                minWidth: 90,
                flex: 0.9,
                valueFormatter: (row) => {
                    let days = dateDifference(new Date(), new Date(row.value))
                    if (isNullUndefined(row.value)) {
                        return "∞"
                    }
                    if (days < 0) {
                        return "Expired"
                    }
                    if (days >= 365) {
                        return " 1+ year"
                    }
                    if (days >= 180) {
                        return "6+ months"
                    }
                    if (days >= 90) {
                        return "3+ months"
                    }
                    if (days >= 30) {
                        return "1+ month"
                    }
                    return days.toFixed(0) + " days"
                },
            },
            {
                headerName: "Rent Frequency",
                field: "billingPeriod",
                minWidth: 135,
                fleX: 1.35,
                valueFormatter: (row) => {
                    if (row.value === "WEEK") {
                        return "Weekly"
                    } else if (row.value === "MNTH") {
                        return "Monthly"
                    } else if (row.value === "ANU") {
                        return "Annually"
                    } else if (row.value === "QT") {
                        return "Quarterly"
                    } else return "N/A"
                },
            },
            {
                headerName: "Type",
                field: "leaseType",
                minWidth: 110,
                flex: 1.1,
                valueFormatter: (row) => {
                    if (row.value === "COM") {
                        return "Commercial"
                    } else if (row.value === "RES") {
                        return "Residential"
                    }
                },
            },
            {
                headerName: "Periodic Charge",
                field: "periodicCharge",
                minWidth: 140,
                flex: 1.4,
                valueFormatter: (row) => {
                    return formatCurrency(row.value)
                },
            },
            {
                headerName: "Tenant",
                field: "tenant",
                minWidth: 200,
                flex: 2,
                renderCell: renderCellExpand,
            },
            {
                headerName: "Balance",
                field: "balance",
                minWidth: 120,
                flex: 1.2,
                valueFormatter: (row) => {
                    return formatCurrency(row.value)
                },
            },
            {
                headerName: "Status",
                field: "status",
                width: "115",
                renderCell: (params) => {
                    return (
                        <Chip variant="outlined" size="small" {...getChipProps(params)} />
                    )
                },
            },
            {
                headerName: "Actions",
                field: "actions",
                width: 100,
                type: "actions",
                getActions: (params) => {
                    let arr = []
                    if (params.row.status !== "DRF") {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Visibility/>}
                                onClick={() => handleViewClick(params.row)}
                                label="View"
                                showInMenu
                            />
                        )
                        arr.push(
                            <GridActionsCellItem
                                icon={<Add/>}
                                label="Add Note"
                                onClick={() => handleNoteClick(params.row)}
                                showInMenu
                            />
                        )
                    }

                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS" ||
                        user.proxyRole === "ACC"
                    ) {
                        arr.push(
							<GridActionsCellItem
								icon={<Contacts />}
								label="Next of Kin"
								onClick={() => handleContactClick(params.row)}
								showInMenu
							/>
						);
                        arr.push(
                            <GridActionsCellItem
                                icon={<LocalAtm/>}
                                label="Receive Payment"
                                onClick={() => handlePaymentClick(params.row)}
                                showInMenu
                            />
                        )
                        if (params.row.status === "ACT" || params.row.status === "SUP") {
                            arr.push(
                                <GridActionsCellItem
                                    icon={<CreditCard/>}
                                    label="Issue Credit"
                                    onClick={() => handleCreditClick(params.row)}
                                    showInMenu
                                />
                            )
                            arr.push(
                                <GridActionsCellItem
                                    icon={<NearMe/>}
                                    label="Issue Charge"
                                    onClick={() => handleChargeClick(params.row)}
                                    showInMenu
                                />
                            )
                        }
                    }

                    if (user.proxyRole === "BLDMG") {
                        if (params.row.status === "DRF") {
                            arr.push(
                                <GridActionsCellItem
                                    icon={<Edit/>}
                                    label="Edit"
                                    onClick={() => handleEditClick(params.row)}
                                    showInMenu
                                />
                            )

                            arr.push(
                                <GridActionsCellItem
                                    icon={<Done/>}
                                    label="Activate"
                                    onClick={() => handleActivateDraftLeaseClick(params.row)}
                                    showInMenu
                                />
                            )

                            arr.push(
                                <GridActionsCellItem
                                    icon={<Clear/>}
                                    label="Delete"
                                    onClick={() => handleDeleteDraftLeaseClick(params.row)}
                                    showInMenu
                                />
                            )
                        }
                    }

                    if (user.proxyRole === "BLDIR" || user.proxyRole === "SYSAD") {
                        if (params.row.status === "ACT") {
                            arr.push(
                                <GridActionsCellItem
                                    icon={<Edit/>}
                                    label="Edit"
                                    onClick={() => handleActiveEditClick(params.row)}
                                    showInMenu
                                />
                            )

                            arr.push(
                                <GridActionsCellItem
                                    icon={<Pause/>}
                                    label="Suspend"
                                    onClick={() => handleSuspendClick(params.row)}
                                    showInMenu
                                />
                            )

                            arr.push(
                                <GridActionsCellItem
                                    icon={<Clear/>}
                                    label="Terminate"
                                    onClick={() => handleTerminateClick(params.row)}
                                    showInMenu
                                />
                            )
                        }

                        if (params.row.status === "DRF") {
                            arr.push(
                                <GridActionsCellItem
                                    icon={<Edit/>}
                                    label="Edit"
                                    onClick={() => handleEditClick(params.row)}
                                    showInMenu
                                />
                            )

                            arr.push(
                                <GridActionsCellItem
                                    icon={<Done/>}
                                    label="Activate"
                                    onClick={() => handleActivateDraftLeaseClick(params.row)}
                                    showInMenu
                                />
                            )

                            arr.push(
                                <GridActionsCellItem
                                    icon={<Clear/>}
                                    label="Delete"
                                    onClick={() => handleDeleteDraftLeaseClick(params.row)}
                                    showInMenu
                                />
                            )
                        }

                        if (params.row.status === "SUP") {
                            arr.push(
                                <GridActionsCellItem
                                    icon={<PlayArrow/>}
                                    label="Reactivate"
                                    onClick={() => handleReActivateClick(params.row)}
                                    showInMenu
                                />
                            )
                        }
                    }
                    return arr
                },
            },
        ]
        //     [],
    )

    return (
        <Permit
            roles="BLDIR,BLDMG,REC,OWN,CS"
            services="PREMIUM,CORE,LEAN"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is an
                        error.
                    </>
                ),
            }}
        >
            <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          width: "100%",
          height: "auto",

          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
                {task === "" && (
                    <TopBarContainer
                        sx={{
              flexWrap: "nowrap",
              flexDirection: { xs: "column", lg: "row" },
                            width: {xs: "100%", lg: "80%"},
                        }}
                        container
                    >
                        <ResponsiveRow
                            item
                            sx={{
                                flexWrap: "nowrap",
                flexDirection: { xs: "column", lg: "row" },

                                width: {xs: "100%", lg: "40%"},
                height: "auto",

                                textAlign: {xs: "center", lg: "start"},
                            }}
                        >
                            <FormControl
                                sx={{
                                    width: {xs: "100%", lg: "50%"},
                  marginBottom: "10px",
                                }}
                            >
                                <InputLabel>Search Billing Code</InputLabel>
                                <OutlinedInput
                                    id="searchRef"
                                    label="Search Billing Code"
                                    sx={{
                                        width: {xs: "100%", lg: "90%"},
                                    }}
                                    onChange={(event) => onTextChange(event)}
                                    inputProps={
                                        <TextField sx={{width: {xs: "100%", lg: "40%"}}}/>
                                    }
                                />
                            </FormControl>
                            <FormControl
                                sx={{
                                    width: {xs: "100%", lg: "50%"},
                  marginBottom: "10px",
                                }}
                            >
                                <InputLabel>Search Tenant</InputLabel>
                                <OutlinedInput
                                    id="searchTenant"
                                    label="Search Tenant"
                                    sx={{
                                        width: {xs: "100%", lg: "90%"},
                                    }}
                                    onChange={(event) => onTextChange(event)}
                                    inputProps={
                                        <TextField sx={{width: {xs: "100%", lg: "40%"}}}/>
                                    }
                                />
                            </FormControl>
                        </ResponsiveRow>

                        <ResponsiveRow
                            sx={{
                                flexDirection: { xs: "column", lg: "row" },
                flexWrap: "nowrap",

                width: { xs: "100%", lg: "60%" },
                height: "auto",

                padding: 0,
                justifyContent: { xs: "center", lg: "flex-end" },
                            }}
                            item
                        >
                            <Permit roles="BLDIR,BLDMG,ACC,CS">
                                <TopBarContainedBrandButton
                                    onClick={handleAddLeaseClick}
                                    sx={{width: {xs: "100%", lg: "150px"}}}
                                >
                                    Add Lease{" "}
                                </TopBarContainedBrandButton>
                            </Permit>

                            <SplitButton buttons={leaseButtons}/>

                            <SplitButton buttons={resendButtons}/>
                        </ResponsiveRow>
                    </TopBarContainer>
                )}
                <br/>
                {(task === "Create" ||
                    task === "Edit" ||
                    task === "ActiveLeaseEdit") && (
                    <AddLeaseModal
                        setTenantModal={setTenantModal}
                        setIsTenantSelect={setIsTenantSelect}
                        billingPeriods={billingPeriods}
                        chargeItemsTotal={chargeItemsTotal}
                        leaseChargesTableRows={leaseChargesTableRows}
                        facilityOptions={facilityOptions}
                        leaseTermOptions={leaseTermOptions}
                        leaseTypeChanged={leaseTypeChanged}
                        leaseTypeOptions={leaseTypeOptions}
                        onRadioChange={onRadioChange}
                        populateUnitIds={populateUnitIds}
                        rentDays={rentDays}
                        saveLease={saveLease}
                        selectedLease={selectedLease}
                        setSelectedLease={setSelectedLease}
                        task={task}
                        updateLease={updateLease}
                        setChargeItems={setChargeItems}
                        chargeItems={chargeItems}
                        handleAddLeaseClose={handleAddLeaseClose}
                        incrementType={incrementType}
                        setIncrementType={setIncrementType}
                    />
                )}

                {showIncrementRentModal && (
                    <IncrementRentModal
                        selectedLeaseIds={rowSelectionModel}
                        showIncrementRentModal={showIncrementRentModal}
                        handleIncrementRentClose={handleIncrementRentClose}
                    />
                )}

                <LeaseUnitSwapModal
                    handleLeaseChargeItemAdd={handleLeaseChargeItemAdd}
                    handleLeaseChargeClose={handleLeaseChargeClose}
                    handleUnitSwapClose={handleUnitSwapClose}
                    handleSwapUnitChange={handleSwapUnitChange}
                    chargeItemsTotal={chargeItemsTotal}
                    innerTask={innerTask}
                    task={task}
                    setTask={setTask}
                    leaseChargesTableRows={leaseChargesTableRows}
                    saveEditUnit={saveEditUnit}
                    selectedLease={selectedLease}
                    saveLeaseCharges={saveLeaseCharges}
                    selectedUnitId={selectedUnitId}
                    showSelectUnit={showSelectUnit}
                    setShowSelectUnit={setShowSelectUnit}
                    swapUnitsTableRows={swapUnitsTableRows}
                    unitOptions={unitOptions}
                    handleAddLeaseUnit={handleAddSwapLeaseUnit}
                />

                {(task === "View" || task === "Unit Swap") && (
                    <LeaseView
                        handleStatementYearChange={handleStatementYearChange}
                        statementYear={statementYear}
                        handleUnitSwapClick={handleUnitSwapClick}
                        selectedLease={selectedLease}
                        handleVoidClick={handleVoidClick}
                        setTask={setTask}
                    />
                )}
                {task === "" && (
                    <MuiDataGrid
                        dataGridColumns={columns}
                        dataGridRows={leases}
                        loading={loading}
                        height="60vh"
                        totalRows={totalLeases}
                        currentSelectionModel={rowSelectionModel}
                        handleSelectedRows={setRowSelectionModel}
                        handlePageLoad={setPaginationModel}
                        serverPagination={true}
                    />
                )}

                {/*RECEIVE PAYMENT MODAL*/}
                <ReceivePayment
                    showPaymentModal={paymentModal}
                    handlePaymentClose={handlePaymentClose}
                    paymentMode={"Lease"}
                    contract={selectedLease}
                    savePayment={savePayment}
                />

                {/*CREATE CREDIT MODAL*/}
                {creditModal && (
                    <CreditNote
                        open={creditModal}
                        onClose={handleCreditClose}
                        voiditem={voiditem}
                        leaseid={selectedLease && selectedLease.id}
                    />
                )}

                {/*CREATE CHARGE MODAL*/}
                {chargeModal && (
                    <DebitNote
                        open={chargeModal}
                        onClose={handleChargeClose}
                        voiditem={voiditem}
                        leaseid={selectedLease && selectedLease.id}
                        leaseIds={
                            bulkChargeAction && rowSelectionModel.length > 1
                                ? rowSelectionModel
                                : []
                        }
                        bulkChargeAction={bulkChargeAction}
                    />
                )}

                {/*ADD NEW USER MODAL*/}
                <AddNewUser
                    openModal={addUserModal}
                    closeModal={handleAddUserClose}
                    searchedIdType={idTypeSearch}
                    searchedIdNo={idNoSearch}
                    saveUser={handlePersonOrgSelect}
                    manageUsers={false}
                />

                {/*ADD NEW ORGANISATION MODAL*/}
                <AddNewOrganisation
                    openModal={addOrganizationModal}
                    saveOrg={handlePersonOrgSelect}
                    searchedName={companySearch}
                    handleSave={true}
                    closeModal={handleAddOrgClose}
                />

                {/*ADD NOTE MODAL*/}
                <AddNoteModal
                    handleNoteClose={handleNoteClose}
                    onTextChange={onTextChange}
                    noteModal={noteModal}
                    saveNote={saveNote}
                    selectedLease={selectedLease}
                    note={note}
                />

                {/*Download/Export Document via Email... Shared Modal*/}
                {downloadModal && (
                    <ExportDocumentsModal
                        showExportDocumentsModal={downloadModal}
                        handleExportDocumentsClose={handleDownloadClose}
                        title={titleText}
                        severity={severityText}
                        body={bodyText}
                        contract={"Lease"}
                        handleIncludeDetailToggle={handleIncludeLeaseDetailToggle}
                        handleStatementYearChange={handleStatementYearChange}
                        includeDetails={includeLeaseDetail}
                        statementYear={statementYear}
                        handleExportDocuments={positiveExportDocsAction}
                        handleCancel={handleDownloadClose}
                    />
                )}

                {/*CONFIRMATION MODAL*/}
                {showConfirmationModal && (
                    <ConfirmationModal
                        showConfirmationModal={showConfirmationModal}
                        handleConfirmationClose={handleConfirmationClose}
                        title={titleText}
                        severity={severityText}
                        body={bodyText}
                        positiveText={positiveText}
                        negativeText={negativeText}
                        positiveAction={positiveAction}
                        negativeAction={handleConfirmationClose}
                    />
                )}

                {/*SUSPEND MODAL*/}
                <SuspendLeaseModal
                    suspendReActivateLeaseForm={suspendReActivateLeaseForm}
                    handleSuspendReactivateClose={handleSuspendReactivateClose}
                    suspendModal={suspendModal}
                    task={task}
                    selectedLease={selectedLease}
                    statusChangeReason={statusChangeReason}
                    onTextChange={onTextChange}
                />

                {/*TERMINATE MODAL*/}
                <ContractTermination
                    showTerminateModal={terminateModal}
                    handleTerminateClose={handleTerminateClose}
                    terminationMode={"Lease"}
                    terminateItem={terminateItem}
                    contract={selectedLease}
                    terminateContract={terminateLease}
                />

                {/*PERSON/ORG SEARCH MODAL*/}
                <PersonOrgSearch
                    contractType={'lease'}
                    showPersonOrgModal={tenantModal}
                    handlePersonOrgClose={handlePersonOrgClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    showAddNewUserForm={showAddNewUserForm}
                    showAddNewOrgForm={showAddNewOrgForm}
                />

                {/*LEASE CONTACT MODAL*/}
                <ContractContactModal
                    handleContactClose={handleContactClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    handleContactClear={handleContactClear}
                    handleIdTypeChange={handleIdTypeChange}
                    searchPersonOrg={searchPersonOrg}
                    contactModal={contactModal}
                    showAddNewContact={showAddNewContact}
                    showAddNewContactForm={showAddNewContactForm}
                    contactTableRows={contactTableRows}
                    foundTenant={foundTenant}
                    idNoSearch={idNoSearch}
                    idTypes={idTypes}
                    onTextChange={onTextChange}
                    selectedContract={selectedLease}
                    contractType={"Lease"}
                />
            </ResponsiveRow>
        </Permit>
    )
}
